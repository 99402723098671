import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import teamBgImageDesktop from '../../assets/images/team/TeamDesktop.png'
import teamBgImageMobile from '../../assets/images/team/TeamMobile.png'
import wavesBgVideoFallback from '../../assets/waves-background-video.png'
import { LinkBehavior, PageBase, Section } from '../../components'
import TeamPersonCard from '../../components/TeamPersonCard'
import { useIsMobile } from '../../hooks/useIsMobile'

const imageKitWavesUrl =
  'https://ik.imagekit.io/1e9yyhfbfj/videos/waves-background-video_4QKaMNCAJ.mp4/ik-master.m3u8?tr=sr-480_720_1080_1440'

const TeamPage: React.FC<{}> = () => {
  const navigate = useNavigate()

  const isMobile = useIsMobile()

  interface Team {
    core: Person[]
    residency: Person[]
  }

  type Person = {
    name: string
    jobRole: string
    image: string
  }

  const [team, setTeam] = useState<Team>()

  useEffect(() => {
    // TODO: future
    // fetch(`/team/${i18n.resolvedLanguage}.json`)
    fetch(`/team/en.json`)
      .then((res) => res.json())
      .then((res) => setTeam(res))
  }, [])

  return (
    <PageBase
      videoOptions={{
        src: imageKitWavesUrl,
        useHls: true,
        overlay: {
          overlayColor: '#FFF'
        },
        posterSrc: wavesBgVideoFallback,
        fallbackSrc: wavesBgVideoFallback
      }}
      navbarOptions={{
        darkMode: true
      }}
      footerOptions={{
        darkMode: true
      }}
      fullHeight={false}
    >
      <Section
        heading='Our'
        title='Team'
        body='We have more than 30 years of experience in startups, gained in two continents and across 5 different countries. StartupGym is an opportunity to learn from past experiences in Italy and beyond, and to help move the ecosystem forward and launch internationally competitive startups.'
        desktopDirection='column'
        needsFooter={false}
        fullHeight={false}
        childrenContainerStyle={{ alignSelf: 'start', marginTop: '-4rem', width: '100%' }}
        sx={{ overflowX: 'hidden' }}
      >
        <Typography
          variant='h4'
          color='secondary'
          fontWeight='bold'
          sx={{ marginTop: isMobile ? '1.75rem' : '3.938rem' }}
        >
          Studio Core Team
        </Typography>
        <Typography
          variant='body2'
          color='secondary'
          fontWeight='400'
          style={{ marginBottom: '2.93rem' }}
        >
          We have assembled a talented team that is passionate about building great products.
        </Typography>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: isMobile ? '1fr 1fr' : '1fr 1fr 2fr',
            gap: isMobile ? '1.125rem' : '4.375rem',
            overflowX: 'auto'
          }}
        >
          {team?.core.map((person: Person, i: number) => {
            const isPersonObjEmpty = Object.keys(person).length === 0

            return (
              <TeamPersonCard
                name={person.name}
                jobRole={person.jobRole}
                image={
                  // eslint-disable-next-line import/no-dynamic-require
                  person.image ? require(`../../assets/images/team/people/${person.image}`) : ``
                }
                key={i}
                onClick={() => (isPersonObjEmpty ? navigate('/careers') : '')}
                sx={{ cursor: isPersonObjEmpty ? 'pointer' : 'default', width: '100%' }}
              />
            )
          })}
        </div>

        <Typography
          variant='h4'
          color='secondary'
          fontWeight='bold'
          style={{ marginTop: isMobile ? '2.5rem' : '5.75rem' }}
        >
          Residency
        </Typography>
        <Typography
          variant='body2'
          color='secondary'
          fontWeight='400'
          style={{ marginBottom: isMobile ? '1.25rem' : '2.93rem' }}
        >
          Passionate and aspiring entrepreneurs who are at the heart of every project launch.
        </Typography>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: isMobile ? '1fr 1fr' : '1fr 1fr 2fr',
            gap: isMobile ? '1.125rem' : '4.375rem',
            overflowX: 'auto'
          }}
        >
          {team?.residency.map((person: Person, i: number) => {
            const isPersonObjEmpty = Object.keys(person).length === 0

            return (
              <TeamPersonCard
                name={person.name}
                jobRole={person.jobRole}
                image={
                  // eslint-disable-next-line import/no-dynamic-require
                  person.image ? require(`../../assets/images/team/people/${person.image}`) : ''
                }
                key={i}
                onClick={() => (isPersonObjEmpty ? navigate('/careers') : '')}
                sx={{ cursor: isPersonObjEmpty ? 'pointer' : 'default', width: '100%' }}
              />
            )
          })}
        </div>

        <div
          style={
            isMobile
              ? {
                  display: 'grid',
                  gridAutoFlow: 'column',
                  gridAutoColumns: 'minmax(16.5rem,1fr)',
                  overflowX: 'auto',
                  marginTop: '5.75rem'
                }
              : {
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 2fr',
                  gap: '4.375rem',
                  marginTop: '5.75rem'
                }
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '16.5rem' }}>
            <Typography variant='h6' fontWeight='800'>
              Get your hands dirty.
            </Typography>

            <Typography
              variant='body1'
              fontWeight='400'
              sx={{
                marginTop: isMobile ? '0.625rem' : '1.875rem'
              }}
            >
              We’re a team of doers. We love what we do, and do what we love. We’re looking for
              like-minded people to get our startups off the ground.
            </Typography>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '16.5rem' }}>
            <Typography variant='h6' fontWeight='800'>
              Be an entrepreneur.
            </Typography>

            <Typography
              variant='body1'
              fontWeight='400'
              sx={{
                marginTop: isMobile ? '0.625rem' : '1.875rem'
              }}
            >
              Entrepreneurship is a team sport. We’re looking for entrepreneurial minded individuals
              to join us and bring to life ambitious projects.
            </Typography>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '16.5rem' }}>
            <Typography variant='h6' fontWeight='800'>
              Become part of the family.
            </Typography>

            <Typography
              variant='body1'
              fontWeight='400'
              sx={{
                marginTop: isMobile ? '0.625rem' : '1.875rem'
              }}
            >
              We’ve got each other’s back. We challenge each other, but we’re also there to lend
              each other a helping hand. What goes around comes around!
            </Typography>
          </div>
        </div>

        <div
          style={{
            marginTop: '6.125rem',
            marginLeft: isMobile ? '-16px' : 'auto',
            marginRight: isMobile ? '-16px' : 'auto'
          }}
        >
          <div
            style={{
              position: 'relative',
              zIndex: 0,
              paddingLeft: isMobile ? '2rem' : '6.875rem'
            }}
          >
            <div style={{ position: 'absolute', paddingTop: isMobile ? '6rem' : '9rem' }}>
              <Typography variant='body1' color='white' fontWeight='400'>
                Ready to build the future of work?
              </Typography>
              <Typography variant='h6' color='white' fontWeight='900'>
                Go on, apply!{' '}
              </Typography>
              <Typography variant='h6' color='white' sx={{ marginTop: '1.25rem' }}>
                We’re always looking for talented people to join our exciting new projects. <br />{' '}
                Check out our current openings to become a part of the next big thing!
              </Typography>
              <Typography
                variant='body1'
                color='white'
                fontWeight='600'
                sx={{ marginTop: '1.875rem' }}
              >
                <LinkBehavior
                  href='/careers'
                  style={{
                    color: 'white',
                    textDecoration: 'none'
                  }}
                >
                  View current openings
                </LinkBehavior>
              </Typography>
            </div>
          </div>
          <img
            src={isMobile ? teamBgImageMobile : teamBgImageDesktop}
            alt='Team'
            height={isMobile ? '530px' : 'auto'}
            style={{ width: isMobile ? '100%' : '100vw' }}
          />
        </div>
      </Section>
    </PageBase>
  )
}

export default TeamPage
