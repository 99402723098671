import { Box, BoxProps, Typography } from '@mui/material'
import React, { FC } from 'react'

export type ManifestoStatementProps = BoxProps & {
  icon: string
  title: string
  body: string
  darkMode?: boolean
}

export const ManifestoStatement: FC<ManifestoStatementProps> = ({
  icon,
  title,
  body,
  darkMode,
  ...props
}) => (
  <Box
    {...props}
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      margin: '1.25rem 0',
      ...props?.sx
    }}
  >
    <Box component='img' src={icon} sx={{ width: '3.125rem', marginRight: '1.25rem' }} />
    <Box>
      <Typography
        gutterBottom
        sx={{ fontSize: '1.75rem', color: darkMode ? 'white' : '#171717', fontWeight: 700 }}
      >
        {title}
      </Typography>
      <Typography
        sx={{ fontSize: '1.125rem', color: darkMode ? 'white' : '#4E4E4E', fontWeight: 400 }}
      >
        {body}
      </Typography>
    </Box>
  </Box>
)

export type TeamDepartmentProps = {
  icon: string
  name: string
}

export const TeamDepartment: FC<TeamDepartmentProps> = ({ icon, name }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: '1 1 4rem' }}>
    <Box component='img' src={icon} sx={{ marginBottom: '1.25rem' }} />
    <Box>
      <Typography sx={{ fontSize: '1.313rem', color: '#4E4E4E', fontWeight: 400 }}>
        {name}
      </Typography>
    </Box>
  </Box>
)
