import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'

import { LinkBehavior } from '../components'

const themeBaseOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      light: '#99CEFC',
      main: '#3F8EFC',
      dark: '#0064E2'
    },
    secondary: {
      light: '#D0D0D0',
      main: '#4E4E4E',
      dark: '#1F1F1F'
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#1C1C1C'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      smartwatch: 0,
      mobile: 425,
      tablet: 768,
      laptop: 1024,
      desktop: 1440
    }
  },
  zIndex: {
    // Stay above tawk.to (2000000000)
    snackbar: 3000000000
  }
}

const light = createTheme({
  ...themeBaseOptions,
  typography: {
    fontFamily: 'Poppins, sans-serif',
    title: {
      fontWeight: 700,
      fontSize: '3.5rem',
      lineHeight: 1.333
    }
  },
  components: {
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave'
      }
    },
    MuiLink: {
      styleOverrides: {
        root: { color: '#171717', fontWeight: 'medium', fontFamily: 'Poppins, sans-serif' }
      },
      defaultProps: {
        component: LinkBehavior,
        underline: 'hover'
      } as any
      // Apparently this bug is still present https://github.com/mui-org/material-ui/issues/16846#issuecomment-518487434
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    },
    MuiIconButton: {
      defaultProps: {
        size: 'large'
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          padding: '0.75rem 3rem'
        },
        contained: {
          fontSize: '1.125rem',
          boxShadow: 'unset !important',
          fontWeight: 700,
          color: 'white'
        },
        containedPrimary: {
          backgroundColor: '#3F8EFC'
        },
        containedSecondary: {
          backgroundColor: 'transparent',
          opacity: 1,
          backdropFilter: 'blur(30px)',
          WebkitBackdropFilter: 'blur(30px)',
          ':hover': {
            backgroundColor: 'transparent'
          }
        },
        text: {
          fontWeight: '700',
          fontSize: '1rem',
          ':hover': {
            backgroundColor: 'unset'
          }
        },
        outlined: {
          padding: '0.5rem 2rem'
        },
        outlinedPrimary: {
          border: '2px solid #3F8EFC',
          ':hover': {
            border: '2px solid #3F8EFC'
          }
        },
        outlinedSecondary: {
          color: 'white',
          border: '2px solid white',
          ':hover': {
            border: '2px solid white'
          }
        },
        outlinedSizeMedium: {
          textTransform: 'none'
        },
        outlinedSizeLarge: {
          fontSize: '1.125rem',
          fontWeight: 700
        },
        sizeLarge: {
          minWidth: '14.563rem',
          height: '4rem'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 'unset',
          boxShadow: '0px 13px 36px #00000029'
        }
      }
    }
  }
})

export const theme = responsiveFontSizes(light, {
  variants: [
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'overline',
    'subtitle1',
    'subtitle2',
    'title' as Variant
  ]
})

// Add typography variant
declare module '@mui/material/styles' {
  interface TypographyVariants {
    title: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true
  }
}

// Add custom breakpoints
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    smartwatch: true
    mobile: true
    tablet: true
    laptop: true
    desktop: true
  }
}
