import { Button, Container, Grid, SxProps, Theme, useTheme } from '@mui/material'
import { motion, MotionStyle, Variants } from 'framer-motion'
import React, { FC, useState } from 'react'

import logoWhite from '../../assets/logo-white.svg'
import { useIsMobile } from '../../hooks/useIsMobile'
import LinkBehavior from '../LinkBehavior'
import { Burger } from './Burger'
import { NavButtonSection, VerticalDivider } from './components'
import NavLinks from './NavLinks'

export type NavbarProps = {
  hideNavButtons?: 'never' | 'always' | 'onMobile'
  sx?: SxProps<Theme>
  darkMode?: boolean
}

const Navbar: FC<NavbarProps> = ({ hideNavButtons = 'onMobile', sx, darkMode }) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const isMobile = useIsMobile()
  const color = darkMode ? theme.palette.primary.main : theme.palette.text.primary
  const textColor = darkMode ? theme.palette.text.secondary : theme.palette.text.primary

  const desktopVariants: Variants = {
    open: {
      opacity: 1,
      x: '10%'
    },
    closed: {
      opacity: 0,
      horizOriginX: '-10%'
    }
  }

  const mobileVariants: Variants = {
    open: {
      opacity: 1,
      x: 0,
      visibility: 'visible',
      transition: {
        ease: 'easeIn'
      }
    },
    closed: {
      opacity: 0,
      x: '100%',
      visibility: 'hidden',
      transition: {
        ease: 'easeOut'
      }
    }
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        paddingY: isMobile ? '3.5rem' : '5rem',
        zIndex: 999,
        position: 'sticky',
        top: 0,
        maxWidth: '1300px',
        ...sx
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <section style={{ display: 'flex' }}>
            <LinkBehavior href='/home'>
              <img
                key={logoWhite}
                src={logoWhite}
                alt='StartupGym Logo'
                width='76px'
                height='54px'
                style={
                  darkMode && (!open || !isMobile)
                    ? {
                        filter:
                          'invert(49%) sepia(51%) saturate(3727%) hue-rotate(199deg) brightness(104%) contrast(98%)'
                      }
                    : {}
                }
              />
            </LinkBehavior>
            <VerticalDivider orientation='vertical' variant='middle' flexItem />
            <Burger open={open} mobile={isMobile} setOpen={setOpen} color={color} />

            {!isMobile && (
              <motion.div
                animate={open ? 'open' : 'closed'}
                variants={desktopVariants}
                transition={{ ease: 'linear', bounce: 0 }}
                style={styles.desktopNavLinks}
              >
                <NavLinks color={textColor} display={open} />
              </motion.div>
            )}

            {isMobile && (
              <motion.div
                animate={open ? 'open' : 'closed'}
                variants={mobileVariants}
                style={{
                  ...styles.mobileNavLinks,
                  flexDirection: window.innerHeight > window.innerWidth ? 'column' : 'row'
                }}
              >
                <NavLinks isMobile display={open} />

                <Button
                  variant='outlined'
                  color='secondary'
                  href='/build-with-us'
                  sx={styles.mobileNavButton}
                >
                  Build with us
                </Button>
              </motion.div>
            )}
          </section>
        </Grid>
        <Grid item xs={4}>
          <NavButtonSection>
            <Button
              variant='outlined'
              href='/build-with-us'
              sx={
                hideNavButtons === 'onMobile'
                  ? { [theme.breakpoints.down('tablet')]: { display: 'none' } }
                  : hideNavButtons === 'always'
                  ? { display: 'none' }
                  : {}
              }
              style={{
                color,
                borderColor: color
              }}
            >
              Build with us
            </Button>
          </NavButtonSection>
        </Grid>
      </Grid>
    </Container>
  )
}

const styles = {
  desktopNavLinks: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    opacity: 0
  } as MotionStyle,
  mobileNavLinks: {
    backgroundColor: 'rgba(0,0,0, 0.99)',
    zIndex: -1,
    height: '100%',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0rem',
    transition: '0.5s',
    visibility: 'hidden'
  } as MotionStyle,
  mobileNavLink: {
    fontSize: '2.25rem',
    fontWeight: 600,
    marginBottom: '1.5rem'
  },
  mobileNavButton: {
    fontSize: '1.5rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    position: 'absolute',
    bottom: '2rem',
    padding: '1rem 3rem'
  }
}

export default Navbar
