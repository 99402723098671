import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
  useTheme
} from '@mui/material'
import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion'
import React, { FC, isValidElement, ReactNode, useMemo } from 'react'

import { useBreakpoint } from '../hooks/useBreakpoint'
import Footer, { FooterProps } from './footer/Footer'

export type CtaButton = ButtonProps & { text: string }

export type SectionProps = ContainerProps & {
  fullHeight?: boolean
  desktopDirection?: 'row' | 'column'
  mobileDirection?: 'row' | 'column'
  innerBoxProps?: BoxProps
  caption?: string | ReactNode
  captionProps?: TypographyProps
  heading: string | ReactNode
  headingProps?: TypographyProps
  title: string | ReactNode
  titleProps?: TypographyProps
  body: string | ReactNode
  bodyProps?: TypographyProps
  needsFooter?: boolean
  footerProps?: FooterProps
  addition?: ReactNode
  children?: ReactNode
  childrenContainerStyle?: SxProps<Theme>
  cta?: CtaButton | CtaButton[] | ReactNode
  ctaGapEnabled?: boolean
  darkMode?: boolean
  leftSideShrinkable?: boolean
  needsAnimation?: boolean
  animationOptions?: HTMLMotionProps<'div'>
}

const Section: FC<SectionProps> = ({
  fullHeight = true,
  desktopDirection = 'row',
  mobileDirection = 'column',
  innerBoxProps,
  caption,
  heading,
  title,
  body,
  captionProps,
  headingProps,
  titleProps,
  bodyProps,
  needsFooter = true,
  footerProps,
  addition,
  children,
  childrenContainerStyle,
  cta,
  // MARK: Not use right now \/
  ctaGapEnabled = true,
  darkMode,
  leftSideShrinkable = false,
  needsAnimation = false,
  animationOptions,
  ...props
}) => {
  const theme = useTheme()
  const isMobile = useBreakpoint(1180)
  const isLayoutSmall = useBreakpoint('sm')
  const layoutDirection = useMemo(
    () => (isMobile ? mobileDirection : desktopDirection),
    [desktopDirection, isMobile, mobileDirection]
  )

  const SectionDataContainer = useMemo(
    () => (needsAnimation ? motion.div : React.Fragment),
    [needsAnimation]
  )

  const flattenCta = [cta].flat(Infinity).filter((el) => el != null)

  return (
    <Container
      maxWidth={false}
      {...props}
      sx={{
        position: 'relative',
        height: fullHeight && layoutDirection === 'row' ? '100vh' : 'auto',
        marginBottom: fullHeight && layoutDirection === 'column' ? 'auto' : 'unset',
        width: '100%',
        maxWidth: '1300px',
        display: 'flex',
        flexDirection: layoutDirection,
        alignItems: 'center',
        justifyContent: layoutDirection === 'column' ? 'start' : 'center',
        marginTop: fullHeight && !isMobile ? '-4rem' : undefined,
        ...props?.sx
      }}
    >
      <Box
        {...innerBoxProps}
        sx={{
          flexGrow: layoutDirection === 'column' ? 0 : 1,
          flexShrink: layoutDirection === 'column' || !leftSideShrinkable ? 0 : 1,
          flexBasis: layoutDirection === 'row' ? '50%' : undefined,
          ...innerBoxProps?.sx
        }}
      >
        <SectionDataContainer
          {...(needsAnimation
            ? {
                ...animationOptions,
                transition: { bounce: 0, ...(animationOptions?.transition ?? ({} as any)) },
                initial: { opacity: 0.1, ...(animationOptions?.initial ?? ({} as any)) },
                animate: { opacity: 1, ...(animationOptions?.animate ?? ({} as any)) },
                exit: { opacity: 0.1, ...(animationOptions?.exit ?? ({} as any)) }
              }
            : {})}
        >
          {/* // TODO: Use SGHeading here instead */}
          {typeof caption === 'string' ? (
            <Typography
              gutterBottom
              {...captionProps}
              sx={{
                fontSize: '1.125rem',
                fontWeight: 700,
                color: darkMode ? 'white' : '#171717',
                lineHeight: 1,
                textTransform: 'uppercase',
                ...captionProps?.sx
              }}
            >
              {caption}
            </Typography>
          ) : (
            caption
          )}
          {typeof heading === 'string' ? (
            <Typography
              {...headingProps}
              sx={{
                fontSize: '3.125rem',
                fontWeight: 400,
                color: darkMode ? 'white' : '#171717',
                lineHeight: 1.08,
                whiteSpace: isMobile ? 'normal' : 'nowrap',
                ...headingProps?.sx
              }}
            >
              {heading}
            </Typography>
          ) : (
            heading
          )}
          {typeof title === 'string' ? (
            <Typography
              gutterBottom
              {...titleProps}
              sx={{
                fontSize: '3.125rem',
                fontWeight: 900,
                color: darkMode ? 'white' : theme.palette.primary.main,
                lineHeight: 1.08,
                textTransform: 'uppercase',
                ...titleProps?.sx
              }}
            >
              {title}
            </Typography>
          ) : (
            title
          )}
          {typeof body === 'string' ? (
            <Typography
              {...bodyProps}
              sx={{
                fontSize: '1.563rem',
                fontWeight: 400,
                color: darkMode ? 'white' : '#4E4E4E',
                lineHeight: 1.36,
                ...bodyProps?.sx
              }}
            >
              {body}
            </Typography>
          ) : (
            body
          )}

          {(flattenCta.length > 0 || ctaGapEnabled) && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: isMobile ? '1.875rem' : '1.25rem',
                gap: '1.25rem 1.875rem',
                flexWrap: 'wrap',
                minHeight: '4rem'
              }}
            >
              <AnimatePresence>
                {flattenCta.map((_cta, i) => {
                  if (!_cta) return null
                  if (isValidElement(_cta)) return _cta
                  const { text, ...buttonProps } = _cta as CtaButton
                  return (
                    <motion.div
                      key={`ctaButton${i}`}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <Button
                        variant={i === 0 ? 'contained' : 'outlined'}
                        size='large'
                        color='primary'
                        {...buttonProps}
                        sx={{ flexGrow: isLayoutSmall ? 1 : 0, ...buttonProps?.sx }}
                      >
                        {text}
                      </Button>
                    </motion.div>
                  )
                })}
              </AnimatePresence>
            </Box>
          )}

          {addition}
        </SectionDataContainer>
      </Box>
      <Box
        sx={{
          ...(layoutDirection === 'column'
            ? { margin: '3rem 0', flex: '0 0' }
            : { margin: '0 0 0 3rem', flex: '1 1 50%' }),
          ...childrenContainerStyle
        }}
      >
        {children}
      </Box>
      {/* // FIXME: On very heigth screen doesnt work */}
      {needsFooter && (
        <Footer
          sx={
            isMobile
              ? { textAlign: 'center', marginTop: '1rem' }
              : { position: 'absolute', bottom: '2rem', left: '0rem' }
          }
          darkMode
          {...footerProps}
        />
      )}
    </Container>
  )
}

export default Section
