import {
  Box,
  BoxProps,
  Card,
  CardMedia,
  CardMediaProps,
  CardProps,
  Typography,
  TypographyProps
} from '@mui/material'
import React, { FC } from 'react'

import userIcon from '../assets/Icon_user.svg'
import { useIsMobile } from '../hooks/useIsMobile'

export type TeamPersonCardProps = BoxProps & {
  image?: CardMediaProps['image']
  name?: string
  jobRole?: string
  description?: string
  cardProps?: CardProps
  cardMediaProps?: Omit<CardMediaProps, 'ref'>
  nameTypograpyProps?: TypographyProps
  jobRoleTypograpyProps?: TypographyProps
  descriptionTypograpyProps?: TypographyProps
  rounded?: boolean
}

const TeamPersonCard: FC<TeamPersonCardProps> = ({
  image,
  name = 'This could be you',
  jobRole = 'View current openings',
  description,
  cardProps,
  cardMediaProps,
  nameTypograpyProps,
  jobRoleTypograpyProps,
  descriptionTypograpyProps,
  rounded = false,
  ...props
}) => {
  const isMobile = useIsMobile()

  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        cursor: props.onClick ? 'pointer' : 'unset',
        width: isMobile ? '9.75rem' : '16.5rem',
        ...props?.sx
      }}
    >
      <Card
        {...cardProps}
        sx={{
          border: image ? '1px solid #D0D0D0' : '2px solid #3F8EFC',
          width: isMobile ? '9.75rem' : '16.5rem',
          height: isMobile ? '11.25rem' : '19rem',
          background: image ? 'unset' : `no-repeat center/3.438rem #3F8EFC1A url(${userIcon})`,
          borderRadius: rounded ? '20px' : 0,
          ...cardProps?.sx
        }}
      >
        {image && (
          <CardMedia
            component='img'
            image={image}
            alt={`Photo of ${name}, ${jobRole}`}
            {...cardMediaProps}
            sx={{ width: '100%', height: '100%', ...cardMediaProps?.sx }}
          />
        )}
      </Card>
      <Typography
        {...nameTypograpyProps}
        sx={{
          font: isMobile
            ? 'normal normal bold 0.875rem/1.188rem Poppins'
            : 'normal normal 700 1.463rem/2.025rem Poppins',
          color: '#171717',
          marginTop: '1rem',
          ...nameTypograpyProps?.sx
        }}
      >
        {name}
      </Typography>
      <Typography
        {...jobRoleTypograpyProps}
        sx={{
          font: isMobile
            ? 'normal normal normal 0.688rem/0.938rem Poppins'
            : 'normal normal 400 1.088rem/1.525rem Poppins',
          color: '#4E4E4E',
          ...jobRoleTypograpyProps?.sx
        }}
      >
        {jobRole}
      </Typography>
      {description && (
        <Typography
          {...descriptionTypograpyProps}
          sx={{
            font: isMobile
              ? 'normal normal normal 0.75rem/1.313rem Poppins'
              : 'normal normal normal 1rem/1.313rem Poppins',
            color: '#4E4E4E',
            mt: '2rem',
            ...descriptionTypograpyProps?.sx
          }}
        >
          {description}
        </Typography>
      )}
    </Box>
  )
}

export default TeamPersonCard
