import { Box } from '@mui/material'
import { Property } from 'csstype'
import { HTMLMotionProps, motion } from 'framer-motion'
import React, { createRef, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'

const BackgroundStyle: React.CSSProperties = {
  position: 'fixed',
  width: '100%',
  minHeight: '100vh',
  objectFit: 'cover',
  zIndex: -2
}

const OverlayStyle: React.CSSProperties = {
  position: 'fixed',
  width: '100%',
  minHeight: '100vh',
  zIndex: -1
}

interface VideoOverlayProps {
  overlayColor: Property.BackgroundColor
  opacity?: Property.Opacity
  motionOptions?: HTMLMotionProps<'div'>
}

export interface BackgroundVideoProps {
  /**
   * Source of the video
   */
  src: string
  /**
   * If the `src` is a HLS manifest / should be played as a Streaming
   */
  useHls?: boolean
  /**
   * Options to change color / opacity of the overlay that is rendered on top of the playing video (to separate the Content from the Background)
   */
  overlay?: VideoOverlayProps
  /**
   * Specifies an image to be shown while the video is downloading (If this is not included, nothing will be shown before the video downloads)
   */
  posterSrc?: string
  /**
   * Fallback image source to be displayed if there are errors playing the video (like Mobile phone is on Low Power Mode / Data saver mode or other problems)
   */
  fallbackSrc?: string
  onEnded?: () => void
  videoWrapperOptions?: HTMLMotionProps<'div'>
  contentStyle?: React.CSSProperties
  children: React.ReactNode
  fullHeight?: boolean
}

const BackgroundVideo: React.FC<BackgroundVideoProps> = ({
  children,
  src,

  useHls,
  overlay,
  posterSrc,
  fallbackSrc,
  contentStyle,
  onEnded = () => {},
  videoWrapperOptions,
  fullHeight = true
}) => {
  const overlayOpts: VideoOverlayProps = overlay ?? { overlayColor: '#000', opacity: 0.4 }
  const nativeVideoRef = createRef<HTMLVideoElement>()
  const reactPlayerRef = createRef<ReactPlayer>()
  const [useFallback, setFallback] = useState(false)
  // MARK: We could just use `poster` attribute for the video tag but on webkit (ios/mac safari) this is slow to render
  const [showPoster, setShowPoster] = useState(true)
  const [didRetryPlay, setDidRetryPlay] = useState(false)

  const handleOnPlay = useCallback(() => {
    setFallback(false)
    setShowPoster(false)
  }, [])

  const handleOnError = useCallback(
    (error: any) => {
      console.log(error)
      if (!didRetryPlay) {
        handleOnPlay()
        const player: HTMLVideoElement | null | undefined = useHls
          ? (reactPlayerRef.current?.getInternalPlayer() as any)
          : nativeVideoRef.current
        player?.play()
        setDidRetryPlay(true)
        return
      }
      // Show something else instead of the video on the BG of the page.
      setFallback(!!fallbackSrc)
    },
    [didRetryPlay, fallbackSrc, handleOnPlay, nativeVideoRef, reactPlayerRef, useHls]
  )

  const handleOnEnd = useCallback(
    (event?: SyntheticEvent<HTMLVideoElement>) => {
      const player: HTMLVideoElement | null | undefined = useHls
        ? (reactPlayerRef.current?.getInternalPlayer() as any)
        : nativeVideoRef.current || event?.target
      if (!player) {
        console.error('Could not get HTML Player')
        return
      }
      onEnded()
      player.play()
    },
    [nativeVideoRef, onEnded, reactPlayerRef, useHls]
  )

  useEffect(() => {
    const video = nativeVideoRef.current
    if (!video || useHls) return

    video
      .play()
      .then(handleOnPlay)
      .catch((error) =>
        // Autoplay not allowed! / paused (Low power mode or Data sever or other domexception) -> Show something else
        handleOnError(error)
      )
  }, [handleOnError, useHls, nativeVideoRef, handleOnPlay])

  return (
    <>
      <motion.div
        {...videoWrapperOptions}
        style={{ ...BackgroundStyle, ...(videoWrapperOptions?.style ?? {}) }}
      >
        <img
          style={{ ...BackgroundStyle, zIndex: showPoster ? -1 : BackgroundStyle.zIndex }}
          src={posterSrc}
          alt='poster-fallback'
        />
        {!useFallback ? (
          useHls ? (
            <ReactPlayer
              ref={reactPlayerRef}
              style={BackgroundStyle}
              url={src}
              onEnded={handleOnEnd}
              onPlay={handleOnPlay}
              onError={handleOnError}
              config={{
                file: {
                  attributes: {
                    // MARK: Image that is displayed while streaming is initializing / loading
                    // poster: posterSrc,
                    style: BackgroundStyle
                  }
                }
              }}
              width={BackgroundStyle.width}
              height={BackgroundStyle.height}
              playsinline
              playing
              muted
              // loop
            />
          ) : (
            <motion.video
              style={BackgroundStyle}
              ref={nativeVideoRef}
              onEnded={handleOnEnd}
              src={src}
              // poster={posterSrc}
              playsInline
              autoPlay
              muted
              // loop
            />
          )
        ) : (
          // TODO: Check if we should use `react-progressive-image`
          <img style={BackgroundStyle} src={fallbackSrc} alt='background-fallback' />
        )}
      </motion.div>
      <motion.div
        {...overlayOpts.motionOptions}
        style={{
          ...OverlayStyle,
          backgroundColor: overlayOpts.overlayColor,
          opacity: overlayOpts.opacity ?? 0.4,
          ...overlayOpts.motionOptions?.style
        }}
      />
      <Box
        sx={{
          display: 'flex',
          top: 0,
          position: 'absolute',
          width: '100%',
          zIndex: 1,
          height: fullHeight ? '100%' : 'unset',
          flexDirection: 'column',
          ...contentStyle
        }}
      >
        {children}
      </Box>
    </>
  )
}

export default BackgroundVideo
