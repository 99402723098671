import { Divider } from '@mui/material'
import { styled } from '@mui/material/styles'

import LinkBehavior from '../LinkBehavior'

export const NavButtonSection = styled('section')`
  display: flex;
  justify-content: flex-end;
  align-self: center;
`

export const NavLink = styled(LinkBehavior)<{ color?: string; display: string }>`
  color: ${({ color }) => color || 'white'};
  text-decoration: none !important;
  pointer-events: ${({ display }) => (display === 'true' ? 'auto' : 'none')};
  cursor: ${({ display }) => (display === 'true' ? 'pointer' : 'default')};
`

export const VerticalDivider = styled(Divider)`
  background: white;
  margin: 0 1.5rem;
  height: 35px;
  align-self: center;
`

export const StyledBurger = styled('button')<{
  open: boolean
  mobile: string
  color: string
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  align-self: center;

  &:focus {
    outline: none;
  }

  div {
    width: 1.5rem;
    height: 0.2rem;
    background: ${({ color, open, mobile }) => (open && mobile === 'true' ? 'white' : color)};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-of-type {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-of-type(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
    }

    :nth-of-type(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`
