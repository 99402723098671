import { useCallback, useEffect, useState } from 'react'

export const useKeyPress = (targetKey: KeyboardEvent['key'], handler = () => {}) => {
  const [keyPressed, setKeyPressed] = useState(false)

  const downHandler = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key !== targetKey) return
      setKeyPressed(true)
      handler()
    },
    [handler, targetKey]
  )

  const upHandler = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === targetKey) setKeyPressed(false)
    },
    [targetKey]
  )

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)

    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [downHandler, upHandler])

  return keyPressed
}
