import { motion } from 'framer-motion'
import React from 'react'

import driveIcon from '../../assets/icons/Drive.svg'
import peopleIcon from '../../assets/icons/People.svg'
import slidesIcon from '../../assets/icons/Slides.svg'
import wavesBgVideoFallback from '../../assets/waves-background-video.png'
import { NavigationArrows, PageBase, Section } from '../../components'
import { useIsMobile } from '../../hooks/useIsMobile'
import { ManifestoStatement } from './components'

const imageKitWavesUrl =
  'https://ik.imagekit.io/1e9yyhfbfj/videos/waves-background-video_4QKaMNCAJ.mp4/ik-master.m3u8?tr=sr-480_720_1080_1440'

const ManifestoPage: React.FC<{}> = () => {
  const isMobile = useIsMobile()

  return (
    <PageBase
      key={`manifesto-${isMobile ? 'mobile' : 'desktop'}`}
      videoOptions={{
        src: imageKitWavesUrl,
        useHls: true,
        posterSrc: wavesBgVideoFallback,
        fallbackSrc: wavesBgVideoFallback,
        overlay: { overlayColor: 'transparent' }
      }}
      navbarOptions={{ darkMode: true }}
      footerOptions={{ darkMode: true }}
      fullHeight={!isMobile}
    >
      <Section
        ctaGapEnabled
        needsAnimation
        animationOptions={
          isMobile
            ? {
                transition: { ease: 'easeInOut', bounce: 0 },
                initial: { x: '-100%', opacity: 0.1 },
                animate: { x: '0%', opacity: 1 },
                exit: { x: '-100%', opacity: 0.1 }
              }
            : {}
        }
        needsFooter={false}
        heading='This is our Manifesto &'
        title='What we stand for'
        body='Everything here is led by Founders, for Founders. We believe capital is a commodity, experience and inner purpose are not. We want to help our Founders succeed in building extraordinary companies.'
        addition={
          <NavigationArrows
            routes={['/manifesto', '/manifesto/meetus']}
            sx={{ marginTop: '2rem' }}
          />
        }
      >
        {/* <motion.div
          {...(isMobile
            ? {
                transition: { ease: 'easeInOut', bounce: 0 },
                initial: { x: '-100%', opacity: 0.1 },
                animate: { x: '0%', opacity: 1 },
                exit: { x: '-100%', opacity: 0.1 }
              }
            : {
                transition: { ease: 'easeInOut', bounce: 0 },
                initial: { x: '100%', opacity: 0 },
                animate: { x: '0%', opacity: 1 },
                exit: { x: '100%', opacity: 0 }
              })}
        >
          <ManifestoStatement
            icon={peopleIcon}
            title='We invest in people'
            body='We are looking for gamechangers looking to build companies that could shift the way we live today. When we find them, we commit much more than just money.'
            sx={{ marginTop: 0 }}
          />
          <ManifestoStatement
            icon={driveIcon}
            title='Operators at heart'
            body='This is not a traditional VC firm, neither an incubator. We invest in people and we build companies from scratch. Our team is only composed by operators and entrepreneurs.'
          />
          <ManifestoStatement
            icon={slidesIcon}
            title='We are driven by purpose'
            body='Our mission is to back Founders driven by a strong purpose of impacting society. Our ultimate goal is to see them succeed and become market leaders.'
            sx={{ marginBottom: 0 }}
          />
        </motion.div> */}
      </Section>
    </PageBase>
  )
}

export default ManifestoPage
