import { Card, CardContent, Grid, IconButton, SxProps, Theme, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { ReactComponent as ArrowIcon } from '../assets/icons/ArrowIcon.svg'

export interface VerticalCarouselProps {
  itemHeight?: number
  itemWidth?: number
  itemSpacing?: number
  /**
   * Specifies the total animation duration but also the delay between each button press to change the visible item.
   */
  itemAnimationDelay?: number
  /**
   * Will be displayed as last item, below all the other Items (cards).
   */
  FooterComponent?: React.ReactNode
  // eslint-disable-next-line react/no-unused-prop-types
  isMobile?: boolean
  /**
   * Called when the user presses the "back" button but there are no more card to be displayed on top of that.
   * `The parent should navigate back.`
   */
  shouldGoBack?: () => void
  /**
   * Called on each change of displayed item. Useful to track which item is getting displayed / if we are on the end of the list.
   */
  onScroll?: (index: number, didEnd: boolean) => void
  data: string[]
}

const CardsContainerPadding = 24

const CarouselCard: React.FC<{ sx?: SxProps<Theme>; text: string }> = ({ sx, text }) => (
  <Card sx={sx}>
    <CardContent
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Typography variant='h5' align='center' color='text.secondary'>
        {text}
      </Typography>
    </CardContent>
  </Card>
)

const MobileVerticalCarousel: React.FC<
  Pick<VerticalCarouselProps, 'itemHeight' | 'itemSpacing' | 'data' | 'FooterComponent'>
> = ({ itemHeight = 275, itemSpacing = 64, FooterComponent, data }) => (
  <Grid
    sx={{ width: '100%', height: '100%' }}
    container
    flexDirection='column'
    justifyContent='center'
    alignItems='center'
    gap={`${itemSpacing}px`}
  >
    {data.map((text, index) => (
      <Grid key={index} sx={{ width: '100%' }} item>
        <CarouselCard
          sx={{
            width: '100%',
            height: itemHeight
          }}
          text={text}
        />
      </Grid>
    ))}
    {FooterComponent && (
      <Grid sx={{ width: '100%' }} item>
        {FooterComponent}
      </Grid>
    )}
  </Grid>
)

/*
 * Codesandbox:
 * https://codesandbox.io/s/vertical-carousel-component-forked-vwix0g?file=/src/VerticalCarousel.jsx
 *
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-vertical-carousel-component-in-react
 */
const DesktopVerticalCarousel: React.FC<VerticalCarouselProps> = ({
  itemWidth = 350,
  itemHeight = 275,
  itemSpacing = 64,
  itemAnimationDelay = 0.8,
  FooterComponent,
  data,
  shouldGoBack,
  onScroll
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [canClick, setCanClick] = useState<boolean>(true)
  const listLength = useMemo(
    () => (FooterComponent ? data.length + 1 : data.length),
    [FooterComponent, data.length]
  )

  // Usd to determine the height/spacing of each item
  const itemVerticalSize = itemHeight + itemSpacing

  // Used to determine which items appear above the active item
  const halfwayIndex = Math.ceil(listLength / 2)

  // Used to determine at what point an item is moved from the top to the bottom (This behaviour is not used on this modified version of the Carousel)
  const shuffleThreshold = halfwayIndex * itemVerticalSize

  const determinePlacement = useCallback(
    (itemIndex: number) => {
      // If these match, the item is active
      if (activeIndex === itemIndex) return 0

      if (itemIndex > activeIndex) return (itemIndex - activeIndex) * itemVerticalSize

      // HERE itemIndex < activeIndex:

      if ((activeIndex - itemIndex) * itemVerticalSize >= shuffleThreshold)
        return (listLength - (activeIndex - itemIndex)) * itemVerticalSize

      return -(activeIndex - itemIndex) * itemVerticalSize
    },
    [activeIndex, listLength, itemVerticalSize, shuffleThreshold]
  )

  const handleClick = useCallback(
    (direction: 'next' | 'prev') => {
      if (!canClick) return
      setCanClick(false)
      setActiveIndex((prevIndex) => {
        if (direction === 'next') {
          if (prevIndex + 1 > listLength - 1) {
            return prevIndex // Not resetting to 0 (as original project)
          }
          return prevIndex + 1
        }
        if (direction === 'prev' && prevIndex === 0) return prevIndex

        if (prevIndex - 1 < 0) {
          return listLength - 1
        }

        return prevIndex - 1
      })
      setTimeout(() => setCanClick(true), itemAnimationDelay * 1000)
    },
    [canClick, listLength, itemAnimationDelay]
  )

  const getItemOpacity = useCallback(
    (itemIndex: number): number => {
      if (itemIndex === activeIndex) return 1
      if (itemIndex < activeIndex) return 0
      // Here itemIndex is GREATER than activeIndex
      return activeIndex + 1 === itemIndex ? 0.65 : 0
    },
    [activeIndex]
  )

  const prevButtonDisabled = activeIndex === 0
  const nextButtonDisabled = activeIndex === listLength - 1
  const ButtonsCtaWidth = 100

  useEffect(() => {
    if (canClick) return
    onScroll?.(activeIndex, activeIndex === listLength - 1)
  }, [activeIndex, canClick, listLength, onScroll])

  // TODO: Swap to flex-basis if we want responsive width

  return (
    <Grid
      sx={{
        height: '100%',
        width: CardsContainerPadding * 2 + ButtonsCtaWidth + itemWidth
      }}
      container
      flexDirection='row'
      alignItems='flex-start'
    >
      {/* // TODO: CHANCE BUTTONS COLORS BECAUSE ARE NOT WORKING */}
      {/* // MARK: CTA Buttons */}
      <Grid
        sx={{ marginTop: `${itemHeight - ButtonsCtaWidth * 2}px` }}
        flexBasis='10%'
        item
        container
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <Grid sx={{ transition: 'scale 0.6s ease' }} item>
          <IconButton
            sx={{
              transition: 'transform 0.3s ease',
              transform: `scale(${nextButtonDisabled ? 1 : 0.7})`
              /* opacity: prevButtonDisabled ? 0.7 : 1,
              transform: `scale(${prevButtonDisabled ? 0.7 : 1})` */
            }}
            onClick={() => (prevButtonDisabled ? shouldGoBack?.() : handleClick('prev'))}
          >
            <ArrowIcon style={{ transform: 'rotate(-90deg)' }} />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            sx={{
              transition: 'transform 0.3s ease',
              opacity: nextButtonDisabled ? 0.7 : 1,
              transform: `scale(${nextButtonDisabled ? 0.7 : 1})`
            }}
            disabled={nextButtonDisabled}
            onClick={() => handleClick('next')}
          >
            <ArrowIcon style={{ transform: 'rotate(90deg)' }} />
          </IconButton>
        </Grid>
      </Grid>

      {/* // MARK: Vertical Carousel card list */}
      <Grid flexBasis='85%' item container>
        <Grid sx={{ height: '100%', padding: `0 ${CardsContainerPadding}px` }} item>
          <div
            style={{
              // position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              height: itemVerticalSize * 2,
              width: itemWidth + 6
            }}
          >
            {data.map((text, index) => (
              <CarouselCard
                key={index}
                sx={{
                  position: 'absolute',
                  width: itemWidth,
                  height: itemHeight,
                  transition: `transform ${itemAnimationDelay}s ease-in-out, opacity ${itemAnimationDelay}s ease-in-out`,
                  transform: `translateY(${determinePlacement(index)}px)`,
                  opacity: getItemOpacity(index)
                }}
                text={text}
              />
            ))}
            {FooterComponent && (
              <div
                style={{
                  position: 'absolute',
                  width: itemWidth,
                  height: itemHeight,
                  transition: `transform ${itemAnimationDelay}s ease-in-out, opacity ${itemAnimationDelay}s ease-in-out`,
                  transform: `translateY(${determinePlacement(listLength - 1)}px)`,
                  opacity: activeIndex !== listLength - 1 ? 0 : 1
                }}
              >
                {FooterComponent}
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

// MARK: "Exporter"
const VerticalCarousel: React.FC<VerticalCarouselProps> = (props) => {
  const { isMobile } = props
  const Component = useMemo(
    () => (isMobile ? MobileVerticalCarousel : DesktopVerticalCarousel),
    [isMobile]
  )
  return <Component {...props} />
}

export default VerticalCarousel
