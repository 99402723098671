import { Box, Button, styled, Typography } from '@mui/material'
import React, { useState } from 'react'

import bgVideoFallback from '../../assets/background-video-fallback.png'
import { PageBase, SGHeading } from '../../components'

const imageKitHomeUrl =
  'https://ik.imagekit.io/1e9yyhfbfj/videos/background-video_24ljQoY_u.mp4/ik-master.m3u8?tr=sr-480_720_1080_1440'

const animationDuration = 0.7
const videoEstimatedDuration = 25.33
const HeadingData = [
  { topText: 'We empower mission', bottomText: 'Driven founders' },
  { topText: 'Building companies ', bottomText: 'That impact the world' },
  { topText: 'We speak execution', bottomText: 'And we move fast' },
  { topText: 'We believe any obstacle', bottomText: 'Can be overcome' },
  { topText: 'With passion', bottomText: 'And courage' },
  { topText: 'This is', bottomText: 'Startupgym' }
]

const fullCycleDuration = videoEstimatedDuration - HeadingData.length * (animationDuration * 2)
const HomeButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  bottom: '5rem',

  [theme.breakpoints.down('sm')]: {}
}))

const HomePage: React.FC<{}> = () => {
  const [playCount, setPlayCount] = useState(0)

  return (
    <PageBase
      // MARK: ENABLING THIS `disableDefaultAnimations` brakes transitions but fixes the "Bubble Transition". Check why
      videoOptions={{
        src: imageKitHomeUrl,
        useHls: true,
        onEnded: () => setPlayCount((count) => count + 1),
        posterSrc: bgVideoFallback,
        fallbackSrc: bgVideoFallback,
        videoWrapperOptions: {
          transition: { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9] },
          initial: { scale: 5 },
          animate: { scale: 1 },
          exit: { scale: 5 }
        }
      }}
      footerOptions={{ hide: true }}
      hideShadow
    >
      <Box
        sx={{
          position: 'absolute',
          height: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white'
        }}
      >
        <SGHeading
          key={playCount}
          variant='white'
          size='big'
          align='center'
          animationDuration={animationDuration}
          cycleDuration={fullCycleDuration}
          data={HeadingData}
        />

        <HomeButton variant='contained' color='secondary' href='/manifesto'>
          <Typography variant='h6' fontWeight='600'>
            Learn more
          </Typography>
        </HomeButton>
      </Box>
    </PageBase>
  )
}

export default HomePage
