import { Box, Grid, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FAQPageType, VerticalCarouselBasePage } from './components'

const VerticalCarouselFooterQuote: React.FC<{ text: string; author: string }> = ({
  text,
  author
}) => (
  <Box>
    <Typography
      sx={{
        position: 'absolute',
        textAlign: 'center',
        font: 'italic normal 900 68px/68px Poppins'
      }}
      color='primary.main'
    >
      &quot;
    </Typography>
    <Grid flexDirection='column' container gap={2} sx={{ padding: 4, paddingLeft: 6 }}>
      <Typography
        sx={{
          font: 'italic normal normal 25px/34px Poppins'
        }}
        color='secondary.dark'
        variant='body1'
      >
        {text}
      </Typography>
      <Typography
        sx={{
          font: 'italic normal bold 25px/34px Poppins'
        }}
        color='secondary.main'
        variant='h5'
      >
        {author}
      </Typography>
    </Grid>
  </Box>
)

const BuildWithUsLaunchersPage: React.FC<{}> = () => {
  const { t } = useTranslation()
  const CardsTexts: string[] = t('buildWithUs.launchers.cards', { returnObjects: true })

  const FooterComponent = useMemo(
    () => (
      <VerticalCarouselFooterQuote
        text={t('buildWithUs.launchers.quote.text')}
        author={t('buildWithUs.launchers.quote.author')}
      />
    ),
    [t]
  )

  return (
    <VerticalCarouselBasePage
      captionText={t('buildWithUs.launchers.caption')}
      headingText={t('buildWithUs.launchers.heading')}
      titleText={t('buildWithUs.launchers.title')}
      bodyText={t('buildWithUs.launchers.body')}
      cardsData={CardsTexts}
      FooterComponent={FooterComponent}
      sectionProps={{ leftSideShrinkable: true }}
      faqPageType={FAQPageType.launchers}
    />
  )
}

export default BuildWithUsLaunchersPage
