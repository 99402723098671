import './config/firebase'

import { Close as CloseIcon } from '@mui/icons-material'
import { IconButton, ThemeProvider } from '@mui/material'
import { SnackbarKey, SnackbarProvider } from 'notistack'
import React, { createRef, useCallback } from 'react'

import { theme } from './config/theme'
import MainRouter from './MainRouter'

const App = () => {
  const notistackRef: React.RefObject<SnackbarProvider | undefined> = createRef()

  const onClickDismiss = useCallback(
    (key: SnackbarKey) => {
      if (!notistackRef?.current) return
      notistackRef.current.closeSnackbar(key)
    },
    [notistackRef]
  )

  const getDismissButton = useCallback(
    (snackKey: SnackbarKey) => (
      <IconButton sx={{ color: 'white' }} onClick={() => onClickDismiss(snackKey)}>
        <CloseIcon />
      </IconButton>
    ),
    [onClickDismiss]
  )

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider ref={notistackRef as any} maxSnack={3} action={getDismissButton}>
        <MainRouter />
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
