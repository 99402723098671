import { Container, Grid, Theme, Typography, useTheme } from '@mui/material'
import { SxProps } from '@mui/system'
import { motion } from 'framer-motion'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

export interface FooterProps {
  className?: string
  sx?: SxProps<Theme>
  hide?: boolean | 'onMobile'
  darkMode?: boolean
}

const Footer: FC<FooterProps> = ({ className, sx, hide, darkMode }) => {
  const theme = useTheme()

  const { t } = useTranslation()
  const footerTextColor = darkMode ? theme.palette.text.secondary : theme.palette.text.primary
  const footerLinkedInIconColor = darkMode ? theme.palette.primary.main : theme.palette.text.primary

  return (
    <Container
      maxWidth={false}
      className={className}
      sx={{
        ...(hide === 'onMobile'
          ? { [theme.breakpoints.down('tablet')]: { display: 'none' } }
          : hide
          ? { display: 'none' }
          : {
              position: 'relative',
              paddingY: '2rem',
              ...sx
            }),
        maxWidth: '1300px'
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Typography
              variant='body2'
              paragraph={false}
              sx={{ color: footerTextColor, fontSize: '0.7rem' }}
            >
              {t('footer.address')}
            </Typography>
            <Typography
              variant='body2'
              paragraph={false}
              sx={{ color: footerTextColor, fontSize: '0.7rem' }}
            >
              {t('footer.company')}
            </Typography>
            <Typography
              variant='body2'
              paragraph={false}
              sx={{
                color: footerTextColor,
                marginTop: '0.5rem',
                fontWeight: 800,
                fontSize: '0.7rem'
              }}
            >
              {t('footer.followUs')}
              &nbsp;&nbsp;
              <a
                href='https://www.linkedin.com/company/startup-gym/'
                target='_blank'
                style={{
                  color: footerLinkedInIconColor,
                  textDecoration: 'none',
                  fontSize: '1.2rem',
                  position: 'relative',
                  top: '0.1rem'
                }}
                rel='noreferrer'
              >
                in
              </a>
            </Typography>
          </motion.div>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Footer
