import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded'
import { Card, Container, Grid, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import wavesBgVideoFallback from '../../assets/waves-background-video.png'
import { LinkBehavior, PageBase } from '../../components'
import FaqCard from '../../components/FaqCard'
import { useIsMobile } from '../../hooks/useIsMobile'
import { FAQPageType } from '../BuildWithUs/components'

const imageKitWavesUrl =
  'https://ik.imagekit.io/1e9yyhfbfj/videos/waves-background-video_4QKaMNCAJ.mp4/ik-master.m3u8?tr=sr-480_720_1080_1440'

type FAQItem = {
  question: string
  answer: string
}

interface FAQData {
  founders: FAQItem[]
  launchers: FAQItem[]
}

const FAQPage: React.FC<{}> = () => {
  const theme = useTheme()
  const location = useLocation()
  const isMobile = useIsMobile()

  const [faq, setFAQ] = useState<FAQItem[]>([])
  const [faqPageType, setFAQPageType] = useState<FAQPageType>()

  useEffect(() => {
    // TODO: future
    // fetch(`/faq/${i18n.resolvedLanguage}.json`)
    fetch(`/faq/en.json`)
      .then((res) => res.json())
      .then((res: FAQData) => {
        setFAQPageType(location.pathname.split('/').pop() as FAQPageType)
        setFAQ(faqPageType === FAQPageType.founders ? res.founders : res.launchers)
      })
  }, [faqPageType, location.pathname])

  return (
    <PageBase
      videoOptions={{
        src: imageKitWavesUrl,
        useHls: true,
        overlay: {
          overlayColor: '#FFF'
        },
        posterSrc: wavesBgVideoFallback,
        fallbackSrc: wavesBgVideoFallback
      }}
      navbarOptions={{
        darkMode: true
      }}
      footerOptions={{
        sx: {
          position: 'static',
          padding: '2rem 0',
          textAlign: isMobile ? 'center' : 'left'
        },
        darkMode: true
      }}
      fullHeight={false}
    >
      <Container>
        <Typography
          variant='body2'
          color='secondary'
          fontWeight='800'
          sx={{ textTransform: 'uppercase', display: 'flex', alignItems: 'center' }}
        >
          {faqPageType}

          <LinkBehavior
            href={`/build-with-us/${faqPageType}`}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <ArrowCircleUpRoundedIcon
              color='primary'
              sx={{ marginLeft: isMobile ? '0.5rem' : '1rem' }}
            />
          </LinkBehavior>
        </Typography>
        <Typography variant='h3' color='primary' fontWeight='bold' style={{ marginBottom: '2rem' }}>
          FAQ
        </Typography>

        <Grid
          container
          columnSpacing={{ xs: 5, sm: 4, md: 0 }}
          sx={{ marginBottom: isMobile ? '6.25rem' : '7.25rem' }}
        >
          <Grid item xs={12} md={8}>
            {faq.map((item: FAQItem, i: number) => (
              <FaqCard question={item.question} answer={item.answer} key={i} />
            ))}
          </Grid>
          <Grid item md={4} sx={{ display: { xs: 'none', lg: 'block' } }}>
            <Card
              style={{
                position: 'fixed',
                maxWidth: '350px',
                maxHeight: '286px',
                width: '30%',
                padding: '2.625rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                color: `${theme.palette.text.primary}`,
                background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
                boxShadow: '0px 23px 36px #00000029'
              }}
            >
              <Typography variant='subtitle1' fontWeight='bold'>
                Do you have more questions about the position?
              </Typography>
              <Typography
                variant='body2'
                sx={{ color: `${theme.palette.text.primary}`, marginBottom: '2rem' }}
              >
                Contact us anytime at info@startupgym.com
              </Typography>

              <Typography variant='body2'>
                We’ll be more than happy to have a chat with you!
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </PageBase>
  )
}

export default FAQPage
