import { Link } from '@mui/material'
import React, { forwardRef } from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

const LinkBehavior = forwardRef<
  any,
  Omit<RouterLinkProps, 'to'> & {
    href: RouterLinkProps['to']
    external?: boolean
  }
>((props, ref) => {
  // eslint-disable-next-line prefer-const
  let { href, external, ...other } = props

  href = href.toString()
  if (href.startsWith('::')) {
    href = href.substring(2)
    other.target = '_blank'
  }

  if (typeof external !== 'boolean') {
    external = false
    try {
      const hrefUrl = new URL(href)
      const pageUrl = new URL(window.location.href)
      if (pageUrl.host !== hrefUrl.host) external = true
    } catch {}
  }

  return external ? (
    <Link
      ref={ref}
      component='a'
      href={href}
      referrerPolicy='no-referrer'
      rel='noreferrer'
      {...other}
    />
  ) : (
    <RouterLink ref={ref} to={href} {...other} />
  )
})

export default LinkBehavior
