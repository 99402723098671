import React, { FC } from 'react'

import { StyledBurger } from './components'

type BurgerProps = {
  open: boolean
  mobile: boolean
  setOpen: (open: boolean) => void
  color: string
}

export const Burger: FC<BurgerProps> = ({ open, mobile, setOpen, color }) => (
  <StyledBurger open={open} onClick={() => setOpen(!open)} color={color} mobile={mobile.toString()}>
    <div />
    <div />
    <div />
  </StyledBurger>
)
