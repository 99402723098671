import { Typography, useTheme } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import { Property } from 'csstype'
import { motion, useAnimation } from 'framer-motion'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

interface HeadingOptions {
  color?: Property.Color // '#fff'
}

interface HeadingData {
  topText?: string
  bottomText?: string
}

interface SGHeadingProps {
  /**
   * `white`: both top and bottom heading are white
   * `colored`: top heading is black, bottom one follow's MUI's theme primary color
   */
  variant?: 'white' | 'colored'
  align?: Property.TextAlign
  /**
   * How long should the cycle last. So if you specify let's say `1` second, all the headings data will be cycled and shown in that time.
   * Default value is `1 second` per heading.
   */
  cycleDuration?: number
  /**
   * How long the fade-out / fade-in transition between two `headings data` should last. Default is: `0.7 second`
   */
  animationDuration?: number
  data: HeadingData[] | HeadingData
  topHeadingOptions?: HeadingOptions
  bottomHeadingOptions?: HeadingOptions
  /**
   * Used to change aspect (size) of the SGHeading.
   * Big result in a `h2` size
   * Medium result in a `h3` size
   * Small result in a `h4` size
   */
  size?: 'big' | 'medium' | 'small'
}

const SGHeading: React.FC<SGHeadingProps> = ({
  variant = 'colored',
  align = 'left',
  animationDuration = 0.7,
  cycleDuration,
  data,
  topHeadingOptions,
  bottomHeadingOptions,
  size = 'big'
}) => {
  const theme = useTheme()
  const animationControls = useAnimation()
  const topHeadingColor =
    topHeadingOptions?.color ?? (variant === 'white' ? theme.palette.text.primary : '#000')
  const bottomHeadingColor =
    bottomHeadingOptions?.color ??
    (variant === 'white' ? theme.palette.text.primary : theme.palette.primary.main)
  const headingType: Variant = useMemo(() => {
    switch (size) {
      case 'big':
        return 'h2'
      case 'medium':
        return 'h3'
      case 'small':
        return 'h4'
      default:
        return 'h2'
    }
  }, [size])
  const headingsData = Array.isArray(data) ? data : [data]
  const fullCycleDuration = cycleDuration ?? headingsData.length

  const [phraseIndex, setPhraseIndex] = useState(0)
  const { topText, bottomText } = headingsData[phraseIndex]

  const handleCycleAnimation = useCallback(async () => {
    await animationControls.start({ opacity: 1 })
    await new Promise((res) => {
      setTimeout(res, (fullCycleDuration / headingsData.length) * 1000)
    })
    await animationControls.start({ opacity: 0 })
    setPhraseIndex((currentIndex) => {
      const nextIndex = currentIndex + 1
      if (nextIndex > headingsData.length - 1) return 0
      return nextIndex
    })
  }, [animationControls, headingsData.length, fullCycleDuration])

  useEffect(() => {
    if (headingsData.length === 1) return
    handleCycleAnimation()
  }, [handleCycleAnimation, headingsData.length, phraseIndex])

  return (
    <motion.div
      transition={{ duration: animationDuration }}
      animate={animationControls}
      style={{
        textAlign: align
      }}
    >
      <Typography style={{ color: topHeadingColor }} variant={headingType} component='h2'>
        {topText}
      </Typography>
      <Typography
        style={{ color: bottomHeadingColor }}
        fontWeight='bold'
        textTransform='uppercase'
        variant={headingType}
        component='h1'
      >
        {bottomText}
      </Typography>
    </motion.div>
  )
}

export default SGHeading
