import { Box, BoxProps, IconButton } from '@mui/material'
import React, { FC, useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import iconChevronBack from '../assets/icons/ChevronBack.svg'
import iconChevronNext from '../assets/icons/ChevronNext.svg'
import { useKeyPress } from '../hooks/useKeypress'

export type NavigationArrowsProps = BoxProps & {
  routes: string[]
  disableKeyboard?: boolean
}

const NavigationArrows: FC<NavigationArrowsProps> = ({
  routes,
  disableKeyboard = false,
  ...props
}) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const activeIndex = useMemo(
    () => routes.findIndex((route) => route === pathname),
    [routes, pathname]
  )

  const previous = useCallback(
    () => activeIndex > 0 && navigate(routes[activeIndex - 1]),
    [activeIndex, navigate, routes]
  )
  const next = useCallback(
    () => activeIndex < routes.length - 1 && navigate(routes[activeIndex + 1]),
    [activeIndex, navigate, routes]
  )

  useKeyPress('ArrowLeft', disableKeyboard ? undefined : previous)
  useKeyPress('ArrowRight', disableKeyboard ? undefined : next)

  return (
    <Box {...props} sx={{ display: 'flex', flexDirection: 'row', ...props?.sx }}>
      <IconButton disabled={activeIndex === 0} onClick={previous}>
        <Box
          component='img'
          src={iconChevronBack}
          style={{
            transition: 'all 0.7s'
          }}
          sx={activeIndex === 0 ? { width: '2.5rem', opacity: 0.7 } : { width: '4.375rem' }}
        />
      </IconButton>
      <IconButton disabled={activeIndex === routes.length - 1} onClick={next}>
        <Box
          component='img'
          src={iconChevronNext}
          style={{
            transition: 'all 0.7s'
          }}
          sx={
            activeIndex === routes.length - 1
              ? { width: '2.5rem', opacity: 0.7 }
              : { width: '4.375rem' }
          }
        />
      </IconButton>
    </Box>
  )
}

export default NavigationArrows
