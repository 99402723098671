import { Container, Grid, Link, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

import IconLanguage from '../../assets/icons/Language.svg'
import IconMail from '../../assets/icons/Mail.svg'
import Cover from '../../assets/images/companies/klink/cover.jpg'
import Logo from '../../assets/images/companies/klink/logo.png'
import Illustration from '../../assets/images/companies/klink/phone.png'
import Founder1Image from '../../assets/images/team/people/color/chris_murphy.jpg'
import Founder2Image from '../../assets/images/team/people/color/philip_jonitz.jpg'
import wavesBgVideoFallback from '../../assets/waves-background-video.png'
import { PageBase, Section } from '../../components'
import TeamPersonCard from '../../components/TeamPersonCard'
import { useBreakpoint } from '../../hooks/useBreakpoint'

const imageKitWavesUrl =
  'https://ik.imagekit.io/1e9yyhfbfj/videos/waves-background-video_4QKaMNCAJ.mp4/ik-master.m3u8?tr=sr-480_720_1080_1440'

const KlinkDetailsPage: React.FC<{}> = () => {
  const isMobile = useBreakpoint(1180)

  return (
    <PageBase
      videoOptions={{
        src: imageKitWavesUrl,
        useHls: true,
        overlay: {
          overlayColor: '#FFF'
        },
        posterSrc: wavesBgVideoFallback,
        fallbackSrc: wavesBgVideoFallback
      }}
      navbarOptions={{
        darkMode: true
      }}
      footerOptions={{
        darkMode: true
      }}
      fullHeight={false}
    >
      <Section
        needsFooter={false}
        fullHeight={isMobile}
        heading
        title='Klink finance'
        body='Klink is a Fintech whose mission is to make saving more fun, profitable and social. The target audience, the Gen Z, is attracted by the gamification logic built into the App: the more you deposit, the more you have the chance to win a weekly prize pool. Additionally, you can share your chances of winning with your friends by creating Social Pools.'
        desktopDirection='row'
        mobileDirection='column'
        sx={{
          // flexGrow: 1,
          // marginTop: isMobile ? 0 : '-5rem',
          justifyContent: 'center',
          flexDirection: isMobile ? 'column-reverse' : 'row-reverse'
          // alignItems: 'start'
        }}
        childrenContainerStyle={{
          margin: isMobile ? '0 0 3rem 0' : '0 3rem 0 0',
          flex: '0',
          alignSelf: 'start',
          // marginTop: isMobile ? '-4rem' : 0,
          flexDirection: 'column',
          // width: '100%',
          display: 'flex',
          gap: '1rem'
          // flexWrap: 'wrap'
        }}
      >
        <Box component='img' src={Logo} sx={{ height: '3.25rem' }} />
        <Typography
          sx={{
            font: 'normal normal normal 0.875rem/1.313rem Poppins',
            color: '#4E4E4E',
            maxWidth: '12.5rem'
          }}
        >
          B2C Fintech on a mission to shape the future of savings.
        </Typography>
        <Link href='::https://klink.finance'>
          <Box component='img' src={IconLanguage} sx={{ verticalAlign: 'middle', mr: 1 }} />
          klink.finance
        </Link>
        <Link href='::mailto:info@klink.com'>
          <Box component='img' src={IconMail} sx={{ verticalAlign: 'middle', mr: 1 }} />
          info@klink.com
        </Link>
      </Section>
      <Box
        component='img'
        src={Cover}
        sx={{ height: '30rem', objectFit: 'cover', objectPosition: '50% 22%' }}
      />
      <Container
        maxWidth={false}
        sx={{
          maxWidth: '1300px',
          mt: '6rem',
          paddingLeft: isMobile ? undefined : '15.011rem !important'
        }}
      >
        <Grid container direction='row' gap='2rem' sx={{}}>
          <Typography
            sx={{
              fontSize: '1.563rem',
              fontWeight: 400,
              color: '#4E4E4E',
              lineHeight: 1.36,
              flex: '1 1'
            }}
          >
            Around 50% of British under 30s do not save on a monthly basis, at the same time more
            than 50% of the UK population buys at least one lottery ticket every month. Gamers lose
            more than 8 billion a year, and 80% of retail investors lose money on the market. Klink
            was created with the aim of educating young people to save, encouraging them through the
            logic of gamification and sharing with friends. With Klink it is impossible to lose
            money, your savings are always protected and for every € 25 deposited, you have a ticket
            to participate in the weekly prize pool of up to 10 million euros.
          </Typography>
          <Box
            component='img'
            src={Illustration}
            sx={{ width: '24rem', maxWidth: '100%', objectFit: 'contain' }}
          />
        </Grid>
        <Typography
          sx={{
            fontSize: '1.563rem',
            fontWeight: 400,
            color: '#4E4E4E',
            lineHeight: 1.36,
            mt: 4
          }}
        >
          Founded and led by Chris and Philip, two truly passionate and motivated founders who run
          the operations from their Berlin HQ, Klink has been launched in London, UK in 2022.
        </Typography>
        <Grid container direction='row' gap='2rem' mt={4} mb={6}>
          <TeamPersonCard
            rounded
            name='Chris Murphy'
            jobRole='Founder'
            image={Founder1Image}
            description='Experience in Twitter and Linkedin as VP Sales, Entrepreneur at Antler'
          />
          <TeamPersonCard
            rounded
            name='Philip Jonitz'
            jobRole='Founder'
            image={Founder2Image}
            description='VC background in Techstars, Motu Ventures and Advisor to several startups'
          />
        </Grid>
      </Container>
    </PageBase>
  )
}

export default KlinkDetailsPage
