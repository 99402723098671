import {
  Box,
  Button,
  ButtonProps,
  Card,
  CardProps,
  Typography,
  TypographyProps
} from '@mui/material'
import React, { FC } from 'react'

import { useIsMobile } from '../hooks/useIsMobile'

export type CareersJobCardProps = CardProps & {
  jobRole: string
  companyName: string
  enrollmentStatus: string
  location: string
  ctaHref?: ButtonProps['href']
  jobRoleTypograpyProps?: TypographyProps
  companyNameTypograpyProps?: TypographyProps
  enrollmentStatusTypograpyProps?: TypographyProps
  locationTypograpyProps?: TypographyProps
  ctaButtonProps?: ButtonProps
}

const CareersJobCard: FC<CareersJobCardProps> = ({
  jobRole,
  companyName,
  enrollmentStatus,
  location,
  ctaHref,
  jobRoleTypograpyProps,
  companyNameTypograpyProps,
  enrollmentStatusTypograpyProps,
  locationTypograpyProps,
  ctaButtonProps,
  ...props
}) => {
  const isMobile = useIsMobile()

  return (
    <Card
      {...props}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: isMobile ? '100%' : '43.75rem',
        minHeight: '6.25rem',
        padding: '1.25rem 1.875rem',
        background: 'white',
        boxShadow: '0px 3px 36px #00000029',
        border: '2px solid transparent',
        transition: 'border 0.3s',
        '&:not(:last-of-type)': {
          marginBottom: '1.25rem'
        },
        ':hover': {
          border: '2px solid #3F8EFC'
        },
        ...props?.sx
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'flex-start' : 'center',
          justifyContent: 'space-between',
          flex: '1 0'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant='h5'
            fontWeight='600'
            {...jobRoleTypograpyProps}
            sx={{
              color: '#171717',
              ...jobRoleTypograpyProps?.sx
            }}
          >
            {jobRole}
          </Typography>
          <Typography
            variant='body2'
            {...companyNameTypograpyProps}
            sx={{
              color: '#4E4E4E',
              ...companyNameTypograpyProps?.sx
            }}
          >
            {companyName}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'row' : 'column',
            marginTop: isMobile ? '0.7rem' : '0'
          }}
        >
          <Typography
            {...enrollmentStatusTypograpyProps}
            sx={{
              font: 'normal normal 400 0.875rem/1.125rem Poppins',
              color: '#4E4E4E',
              textTransform: 'uppercase',
              textAlign: 'right',
              ...enrollmentStatusTypograpyProps?.sx
            }}
          >
            {enrollmentStatus}
          </Typography>
          {isMobile && (
            <Typography
              sx={{
                font: 'normal normal 400 0.875rem/1.125rem Poppins',
                color: '#4E4E4E',
                textTransform: 'uppercase',
                margin: '0 0.3rem'
              }}
            >
              -
            </Typography>
          )}
          <Typography
            {...locationTypograpyProps}
            sx={{
              font: 'normal normal 400 0.875rem/1.125rem Poppins',
              color: '#4E4E4E',
              textTransform: 'uppercase',
              textAlign: 'right',
              ...locationTypograpyProps?.sx
            }}
          >
            {location}
          </Typography>
        </Box>
      </Box>
      <a href={ctaHref} target='_blank' rel='noreferrer' style={{ textDecoration: 'none' }}>
        <Button
          variant='text'
          disableRipple
          disableFocusRipple
          disableTouchRipple
          {...ctaButtonProps}
          sx={{
            marginLeft: '1rem',
            maxWidth: isMobile ? '3.438rem' : 'unset',
            paddingRight: isMobile ? 0 : 'unset',
            paddingLeft: isMobile ? 0 : '',
            textAlign: isMobile ? 'right' : 'left',
            ...ctaButtonProps?.sx
          }}
        >
          Apply Now
        </Button>
      </a>
    </Card>
  )
}

export default CareersJobCard
