/* eslint-disable react/no-danger */
import { Card, CardProps, Typography, TypographyProps } from '@mui/material'
import React, { FC } from 'react'

import { useIsMobile } from '../hooks/useIsMobile'

export type FaqCardProps = CardProps & {
  question: string
  answer: string
  questionTypograpyProps?: TypographyProps
  answerTypograpyProps?: TypographyProps
}

const FaqCard: FC<FaqCardProps> = ({
  question,
  answer,
  questionTypograpyProps,
  answerTypograpyProps,
  ...props
}) => {
  const isMobile = useIsMobile()

  return (
    <Card
      {...props}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: isMobile ? '100%' : '42rem',
        padding: isMobile ? '1.875rem' : '2.25rem 2.5rem',
        marginBottom: '1rem',
        ...props?.sx
      }}
    >
      <Typography
        {...questionTypograpyProps}
        sx={{
          font: 'normal normal 600 1.338rem Poppins',
          color: '#171717',
          ...questionTypograpyProps?.sx
        }}
      >
        {question}
      </Typography>
      <Typography
        {...answerTypograpyProps}
        sx={{
          font: 'normal normal 400 0.9rem Poppins',
          color: '#4E4E4E',
          marginTop: isMobile ? '0.625rem' : 0,
          ...answerTypograpyProps?.sx
        }}
      >
        {/**
         * MARK: No panic, it's safe since it's our own hard-coded html
         */}
        <span dangerouslySetInnerHTML={{ __html: answer }} />
      </Typography>
    </Card>
  )
}

export default FaqCard
