import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useRef } from 'react'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'

import useScroll from './hooks/useScroll'
import {
  BuildWithUsFoundersPage,
  BuildWithUsLaunchersPage,
  BuildWithUsPage,
  CareersPage,
  FAQPage,
  HomePage,
  ManifestoMeetusPage,
  ManifestoPage,
  TeamPage
} from './pages'
import CompaniesPage from './pages/Companies/CompaniesPage'
import KlinkDetailsPage from './pages/Companies/KlinkDetails'
import QuistoDetailsPage from './pages/Companies/QuistoDetails'

const AnimatedRoutes = () => {
  const location = useLocation()

  useEffect(() => {
    document.getElementById('root')?.scrollIntoView({ behavior: 'smooth' })
  }, [location.key])

  return (
    <AnimatePresence initial={false} exitBeforeEnter={false}>
      <Routes location={location} key={location.pathname}>
        <Route path='/build-with-us' element={<BuildWithUsPage />} />
        <Route path='/build-with-us/launchers' element={<BuildWithUsLaunchersPage />} />
        <Route path='/build-with-us/founders' element={<BuildWithUsFoundersPage />} />
        <Route path='/manifesto' element={<ManifestoPage />} />
        <Route path='/manifesto/meetus' element={<ManifestoMeetusPage />} />
        <Route path='/team' element={<TeamPage />} />
        <Route path='/companies' element={<CompaniesPage />} />
        <Route path='/companies/klink' element={<KlinkDetailsPage />} />
        <Route path='/companies/quisto' element={<QuistoDetailsPage />} />
        <Route path='/careers' element={<CareersPage />} />
        <Route path='/faq/founders' element={<FAQPage />} />
        <Route path='/faq/launchers' element={<FAQPage />} />
        <Route path='/*' element={<HomePage />} />
      </Routes>
    </AnimatePresence>
  )
}

const MainRouter = () => (
  <BrowserRouter>
    <AnimatedRoutes />
  </BrowserRouter>
)

export default MainRouter
