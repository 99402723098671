import { Container, Grid, Link, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

import IconLanguage from '../../assets/icons/Language.svg'
import IconMail from '../../assets/icons/Mail.svg'
import Cover from '../../assets/images/companies/quisto/cover.jpg'
import Logo from '../../assets/images/companies/quisto/logo.png'
import Illustration from '../../assets/images/companies/quisto/phone.png'
import Founder2Image from '../../assets/images/team/people/color/pietro_bini_smaghi.jpg'
import Founder1Image from '../../assets/images/team/people/color/stefano_raffaglio.jpg'
import wavesBgVideoFallback from '../../assets/waves-background-video.png'
import { PageBase, Section } from '../../components'
import TeamPersonCard from '../../components/TeamPersonCard'
import { useBreakpoint } from '../../hooks/useBreakpoint'

const imageKitWavesUrl =
  'https://ik.imagekit.io/1e9yyhfbfj/videos/waves-background-video_4QKaMNCAJ.mp4/ik-master.m3u8?tr=sr-480_720_1080_1440'

const QuistoDetailsPage: React.FC<{}> = () => {
  const isMobile = useBreakpoint(1180)

  return (
    <PageBase
      videoOptions={{
        src: imageKitWavesUrl,
        useHls: true,
        overlay: {
          overlayColor: '#FFF'
        },
        posterSrc: wavesBgVideoFallback,
        fallbackSrc: wavesBgVideoFallback
      }}
      navbarOptions={{
        darkMode: true
      }}
      footerOptions={{
        darkMode: true
      }}
      fullHeight={false}
    >
      <Section
        needsFooter={false}
        fullHeight={isMobile}
        heading
        title='Quisto'
        body='Quisto is on a mission to make the food service supply chain more efficient and sustainable. The market is made by retailers, who want to place orders more efficiently and 24/7and access a wider product catalogue, and wholesalers, who want to scale up their business and improve customer relationships through value add services. Unfortunately, the market is dominated by price elasticity, undifferentiated products, and tough competition solved by non-transparency along the value chain. 

        Quisto offers a collaborative digital platform coupled with direct product logistics in areas with the highest residential and commercial density. As a result, the market benefits from technological innovation and logistics optimisation.
        '
        desktopDirection='row'
        mobileDirection='column'
        sx={{
          // flexGrow: 1,
          // marginTop: isMobile ? 0 : '-5rem',
          justifyContent: 'center',
          flexDirection: isMobile ? 'column-reverse' : 'row-reverse'
          // alignItems: 'start'
        }}
        childrenContainerStyle={{
          margin: isMobile ? '0 0 3rem 0' : '0 3rem 0 0',
          flex: '0',
          alignSelf: 'start',
          // marginTop: isMobile ? '-4rem' : 0,
          flexDirection: 'column',
          // width: '100%',
          display: 'flex',
          gap: '1rem'
          // flexWrap: 'wrap'
        }}
      >
        <Box component='img' src={Logo} sx={{ height: '3.25rem' }} />
        <Typography
          sx={{
            font: 'normal normal normal 0.875rem/1.313rem Poppins',
            color: '#4E4E4E',
            maxWidth: '12.5rem'
          }}
        >
          The platform on a mission to make the foodservice supply chain more sustainable.
        </Typography>
        <Link href='::https://www.quisto.it'>
          <Box component='img' src={IconLanguage} sx={{ verticalAlign: 'middle', mr: 1 }} />
          www.quisto.it
        </Link>
        <Link href='::mailto:info@quisto.it'>
          <Box component='img' src={IconMail} sx={{ verticalAlign: 'middle', mr: 1 }} />
          info@quisto.it
        </Link>
      </Section>
      <Box
        component='img'
        src={Cover}
        sx={{ height: '30rem', objectFit: 'cover', objectPosition: '50% 22%' }}
      />
      <Container
        maxWidth={false}
        sx={{
          maxWidth: '1300px',
          mt: '6rem',
          paddingLeft: isMobile ? undefined : '15.011rem !important'
        }}
      >
        <Grid container direction='row' gap='2rem' sx={{}}>
          <Typography
            sx={{
              fontSize: '1.563rem',
              fontWeight: 400,
              color: '#4E4E4E',
              lineHeight: 1.36,
              flex: '1 1'
            }}
          >
            The size of the out-of-home Ho.Re.Ca. industry in Italy is estimated to be around 17
            billion euros and more than 180 billion euros in Europe. Still, it is a market that
            resisted innovation and digitalisation and lacks efficiency with little focus on the
            negative impact of its supply chains. The market is dominated by evasion and cash
            transactions, and logistics need to be optimised to benefit the environment and cities.
          </Typography>
        </Grid>
        <Typography
          sx={{
            fontSize: '1.563rem',
            fontWeight: 400,
            color: '#4E4E4E',
            lineHeight: 1.36,
            mt: 4
          }}
        >
          Founded in Milan in 2022, it is a service conceived and launched by Stefano Raffaglio and
          Pietro Bini Smaghi. On March 2023, Quisto closed a pre-seed round of 800.000 euros led by
          StartupGym.
        </Typography>
        <Grid container direction='row' gap='2rem' mt={4} mb={6}>
          <TeamPersonCard
            rounded
            name='Stefano Raffaglio'
            jobRole='Founder'
            image={Founder1Image}
            description='Food distribution expert of the out-of-home sector with 20+ years of experience in the Italian and European markets.'
          />
          <TeamPersonCard
            rounded
            name='Pietro Bini Smaghi'
            jobRole='Founder'
            image={Founder2Image}
            description='Growth background in Lazada, Flowerstore Group, and various startups.'
          />
        </Grid>
      </Container>
    </PageBase>
  )
}

export default QuistoDetailsPage
