import { Box, ButtonBase, ButtonBaseProps, Grid, Typography } from '@mui/material'
import React, { FC } from 'react'

export type CompanyTileProps = {
  icon: string
  name: string
  shortDescription: string
} & ButtonBaseProps

const CompanyTile: FC<CompanyTileProps> = ({ icon, name, shortDescription, ...props }) => (
  <ButtonBase {...props} sx={{ px: 4, py: 2, maxWidth: '100%', ...props.sx }}>
    <Grid
      container
      direction='row'
      wrap='nowrap'
      alignItems='center'
      sx={{ height: '5rem', width: '20rem', textAlign: 'initial' }}
    >
      <Grid item sx={{ height: '100%', marginRight: '1.875rem' }}>
        <Box component='img' src={icon} sx={{ height: '100%' }} />
      </Grid>
      <Grid item>
        <Typography sx={{ font: 'normal normal bold 1.125rem/1.688rem Poppins' }}>
          {name}
        </Typography>
        <Typography
          sx={{ font: 'normal normal normal 0.875rem/1.313rem Poppins', color: '#4E4E4E' }}
        >
          {shortDescription}
        </Typography>
      </Grid>
    </Grid>
  </ButtonBase>
)

export default CompanyTile
