import { Box, Button, Grid, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'

import wavesBgVideoFallback from '../../assets/waves-background-video.png'
import { PageBase, Section } from '../../components'
import CareersJobCard from '../../components/CareersJobCard'
import { useIsMobile } from '../../hooks/useIsMobile'

const imageKitWavesUrl =
  'https://ik.imagekit.io/1e9yyhfbfj/videos/waves-background-video_4QKaMNCAJ.mp4/ik-master.m3u8?tr=sr-480_720_1080_1440'

const CareersPage: React.FC<{}> = () => {
  const theme = useTheme()

  const isMobile = useIsMobile()

  interface Careers {
    startupgym: Job[]
    stealthstartup: Job[]
  }

  type Job = {
    role: string
    company: string
    type: string
    location: string
    link: string
  }

  enum JobCategory {
    all = 'All',
    startupgym = 'StartupGym',
    stealthstartup = 'Stealth Startup'
  }

  const [careers, setCareers] = useState<Careers>()
  const [jobs, setJobs] = useState<Job[]>([])
  const [jobCategorySelected, setJobCategorySelected] = useState<JobCategory>(JobCategory.all)

  const setTotalJobs = useCallback(
    (careersData: Careers | undefined) => {
      setJobCategorySelected(JobCategory.all)

      setJobs([...(careersData?.startupgym || []), ...(careersData?.stealthstartup || [])])
    },
    [JobCategory.all]
  )

  const handleOnJobCategorySelected = useCallback((jobCategory: JobCategory, jobsArr?: Job[]) => {
    setJobCategorySelected(jobCategory)
    setJobs(jobsArr ?? [])
  }, [])

  useEffect(() => {
    // TODO: future
    // fetch(`/careers/${i18n.resolvedLanguage}.json`)
    fetch(`/careers/en.json`)
      .then((res) => res.json())
      .then((res) => setCareers(res))
  }, [])

  useEffect(() => {
    setTotalJobs(careers)
  }, [setTotalJobs, careers])

  return (
    <PageBase
      videoOptions={{
        src: imageKitWavesUrl,
        useHls: true,
        overlay: {
          overlayColor: '#FFF'
        },
        posterSrc: wavesBgVideoFallback,
        fallbackSrc: wavesBgVideoFallback
      }}
      navbarOptions={{
        darkMode: true
      }}
      footerOptions={{
        // sx: {
        //   position: 'static',
        //   padding: '2rem 0',
        //   textAlign: isMobile ? 'center' : 'left'
        // },
        darkMode: true
      }}
      fullHeight={false}
    >
      <Section
        heading=''
        title='Careers'
        body='We are looking for entrepreneurs to build new ideas with. Apply here to be selected as one of our Founders in Residence'
        desktopDirection='column'
        needsFooter={false}
        fullHeight={false}
        childrenContainerStyle={{ alignSelf: 'start', width: '100%' }}
        sx={{ height: '100%' }}
        innerBoxProps={{ sx: { flexBasis: 'unset', alignSelf: 'start' } }}
      >
        {/* <Typography
          variant='h2'
          color='primary'
          fontWeight='bold'
          style={{
            textTransform: 'uppercase',
            marginBottom: '2rem'
          }}
        >
          Careers
        </Typography>
        <Typography variant='body1' color='secondary' style={{ marginBottom: '2.438rem' }}>
          We are currently looking for these open positions.
          {!isMobile && <br />} Apply here, we are waiting for you.
        </Typography> */}
        <Grid container spacing={3} sx={{ marginBottom: isMobile ? '6.25rem' : '7.25rem' }}>
          <Grid item xs={12} lg={4}>
            <Box
              sx={
                isMobile
                  ? {
                      display: 'flex',
                      flexWrap: 'nowrap',
                      overflow: 'auto',
                      WebkitOverflowScrolling: 'touch',
                      ScrollbarWidth: 'none',
                      '&::-webkit-scrollbar': {
                        display: 'none'
                      },
                      '&::-moz-scrollbar': {
                        display: 'none'
                      }
                    }
                  : {
                      display: 'flex',
                      flexDirection: 'column',
                      maxWidth: '14.563rem',
                      width: '100%'
                    }
              }
            >
              <Button
                variant='text'
                sx={{
                  display: 'flex',
                  marginBottom: isMobile ? 0 : '1rem',
                  justifyContent: 'space-between',
                  left: '-1.375rem',
                  padding: '0.188rem 1.375rem',
                  borderRadius: '17px',
                  textTransform: 'none',
                  flex: isMobile ? '0 0 auto' : ''
                }}
                style={
                  jobCategorySelected === JobCategory.all
                    ? { color: `${theme.palette.primary.main}` }
                    : {}
                }
                onClick={() => setTotalJobs(careers)}
              >
                <Typography
                  variant='body1'
                  color={jobCategorySelected === JobCategory.all ? 'primary' : 'secondary'}
                  sx={{ marginRight: isMobile ? '1.875rem' : 0 }}
                >
                  All jobs
                </Typography>

                <Typography variant='body1' color='secondary' fontWeight='600'>
                  {(careers?.startupgym?.length || 0) + (careers?.stealthstartup?.length || 0)}
                </Typography>
              </Button>

              <Button
                variant='text'
                sx={{
                  display: 'flex',
                  marginBottom: isMobile ? 0 : '1rem',
                  justifyContent: 'space-between',
                  left: '-1.375rem',
                  padding: '0.188rem 1.375rem',
                  textTransform: 'none',
                  borderRadius: '17px',
                  flex: isMobile ? '0 0 auto' : '',
                  ':hover': {
                    backgroundColor: '#3F8EFC66'
                  }
                }}
                style={
                  jobCategorySelected === JobCategory.startupgym
                    ? { backgroundColor: '#3F8EFC66', color: `${theme.palette.primary.main}` }
                    : {}
                }
                onClick={() =>
                  handleOnJobCategorySelected(JobCategory.startupgym, careers?.startupgym)
                }
              >
                <Typography
                  variant='body1'
                  color={jobCategorySelected === JobCategory.startupgym ? 'primary' : 'secondary'}
                  sx={{ marginRight: isMobile ? '1.875rem' : 0 }}
                >
                  {JobCategory.startupgym}
                </Typography>

                <Typography
                  variant='body1'
                  color={jobCategorySelected === JobCategory.startupgym ? 'primary' : 'secondary'}
                >
                  {careers?.startupgym?.length || 0}
                </Typography>
              </Button>

              <Button
                variant='text'
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0.188rem 1.375rem',
                  left: '-1.375rem',
                  textTransform: 'none',
                  borderRadius: '17px',
                  flex: isMobile ? '0 0 auto' : '',
                  ':hover': {
                    backgroundColor: '#3F8EFC66'
                  }
                }}
                style={
                  jobCategorySelected === JobCategory.stealthstartup
                    ? { backgroundColor: '#3F8EFC66' }
                    : {}
                }
                onClick={() =>
                  handleOnJobCategorySelected(JobCategory.stealthstartup, careers?.stealthstartup)
                }
              >
                <Typography
                  variant='body1'
                  color={
                    jobCategorySelected === JobCategory.stealthstartup ? 'primary' : 'secondary'
                  }
                  sx={{ marginRight: isMobile ? '1.875rem' : 0 }}
                >
                  {JobCategory.stealthstartup}
                </Typography>
                <Typography
                  variant='body1'
                  color={
                    jobCategorySelected === JobCategory.stealthstartup ? 'primary' : 'secondary'
                  }
                >
                  {careers?.stealthstartup?.length || 0}
                </Typography>
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} lg={8}>
            {jobs?.map((job: Job, i: number) => (
              <CareersJobCard
                jobRole={job.role}
                companyName={job.company}
                enrollmentStatus={job.type}
                location={job.location}
                ctaHref={job.link}
                key={i}
              />
            ))}
          </Grid>
        </Grid>
      </Section>
    </PageBase>
  )
}

export default CareersPage
