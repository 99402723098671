import { Box } from '@mui/system'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import klinkIcon from '../../assets/images/companies/klink.png'
import pagaprestoIcon from '../../assets/images/companies/pagapresto.png'
import quistoIcon from '../../assets/images/companies/quisto.png'
import wavesBgVideoFallback from '../../assets/waves-background-video.png'
import { PageBase, Section } from '../../components'
import { useIsMobile } from '../../hooks/useIsMobile'
import CompanyTile from './CompanyTile'

const imageKitWavesUrl =
  'https://ik.imagekit.io/1e9yyhfbfj/videos/waves-background-video_4QKaMNCAJ.mp4/ik-master.m3u8?tr=sr-480_720_1080_1440'

const CompaniesPage: React.FC<{}> = () => {
  const navigate = useNavigate()

  const isMobile = useIsMobile()

  return (
    <PageBase
      videoOptions={{
        src: imageKitWavesUrl,
        useHls: true,
        overlay: {
          overlayColor: '#FFF'
        },
        posterSrc: wavesBgVideoFallback,
        fallbackSrc: wavesBgVideoFallback
      }}
      navbarOptions={{
        darkMode: true
      }}
      footerOptions={{
        darkMode: true
      }}
      fullHeight
    >
      <Section
        needsFooter={false}
        fullHeight={false}
        heading
        title='Portfolio companies'
        body='We are on a mission to build and invest in Founders with the ambition to become market leaders by shaping the future of our society. We are a pre-seed investor: we invest in Founders, not ideas.'
        desktopDirection='column'
        sx={{ flexGrow: 1, marginTop: isMobile ? 0 : '-5rem', justifyContent: 'center' }}
        childrenContainerStyle={{
          alignSelf: 'start',
          marginTop: isMobile ? '-4rem' : 0,
          flexDirection: 'row',
          width: '100%',
          display: 'flex',
          gap: '2.5rem',
          flexWrap: 'wrap'
        }}
      >
        {/* <CompanyTile
          icon={eorekaIcon}
          name='Eoreka'
          shortDescription='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        />
        <CompanyTile
          icon={pagaprestoIcon}
          name='PagaPresto'
          shortDescription='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        /> */}
        <CompanyTile
          icon={klinkIcon}
          name='Klink'
          shortDescription='B2C Fintech on a mission to shape the future of savings.'
          onClick={() => navigate('/companies/klink')}
        />
        <CompanyTile
          icon={quistoIcon}
          name='Quisto'
          shortDescription='The platform on a mission to make the foodservice supply chain more sustainable.'
          onClick={() => navigate('/companies/quisto')}
        />
      </Section>
    </PageBase>
  )
}

export default CompaniesPage
