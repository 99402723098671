import { Box } from '@mui/material'
import { HTMLMotionProps, motion, Variants } from 'framer-motion'
import React, { useEffect, useMemo, useState } from 'react'

import BackgroundVideo, { BackgroundVideoProps } from './BackgroundVideo'
import Footer, { FooterProps } from './footer/Footer'
import Navbar, { NavbarProps } from './navbar/Navbar'

export enum DefaultPageBaseAnimationsKeys {
  Faded = 'Faded',
  Visible = 'Visible',
  Hidden = 'Hidden'
}
export const DefaultPageBaseAnimations: Variants = {
  [DefaultPageBaseAnimationsKeys.Faded]: {
    opacity: 0.4
  },
  [DefaultPageBaseAnimationsKeys.Visible]: {
    opacity: 1
  },
  [DefaultPageBaseAnimationsKeys.Hidden]: {
    opacity: 0
  }
}

/**
 * Already animates the content with a fade-in / fade-out animation on presentation / dismiss (`can be disabled`)
 */
const PageBase: React.FC<{
  disableDefaultAnimations?: boolean
  videoOptions: Omit<BackgroundVideoProps, 'children'>
  children: React.ReactNode
  navbarOptions?: NavbarProps
  footerOptions?: FooterProps
  fullHeight?: boolean
  hideShadow?: boolean
}> = ({
  disableDefaultAnimations = false,
  videoOptions,
  children,
  navbarOptions,
  footerOptions,
  fullHeight,
  hideShadow = false
  // TODO: in future implement this https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
}) => {
  const [shouldRenderShadow, setShouldRenderShadow] = useState(false)

  const Container = useMemo(
    () => (disableDefaultAnimations ? React.Fragment : motion.div),
    [disableDefaultAnimations]
  )
  const defaultAnimationDuration = 0.7
  const containerProps: {} | HTMLMotionProps<'div'> = useMemo(
    () =>
      disableDefaultAnimations
        ? {}
        : {
            variants: DefaultPageBaseAnimations,
            initial: DefaultPageBaseAnimationsKeys.Faded,
            animate: DefaultPageBaseAnimationsKeys.Visible,
            exit: DefaultPageBaseAnimationsKeys.Hidden,
            transition: { duration: defaultAnimationDuration },
            onAnimationComplete: () => setShouldRenderShadow(true)
          },
    [disableDefaultAnimations]
  )

  useEffect(() => {
    if (!disableDefaultAnimations) return () => {}
    const id = setTimeout(() => setShouldRenderShadow(true), defaultAnimationDuration * 1000)
    return () => clearTimeout(id)
  }, [disableDefaultAnimations])

  return (
    <Container {...containerProps}>
      <BackgroundVideo {...videoOptions} fullHeight={fullHeight}>
        <Navbar
          hideNavButtons='onMobile'
          sx={navbarOptions?.sx}
          darkMode={navbarOptions?.darkMode}
        />
        {!hideShadow && shouldRenderShadow && (
          <motion.div transition={{ duration: 0 }} exit={{ opacity: 0 }}>
            <Box
              sx={{
                width: '100%',
                background: `transparent linear-gradient(180deg, ${
                  !navbarOptions?.darkMode ? '#73abfded' : '#FFFFFF'
                } 56%, #FFFFFF00 100%) 0% 0% no-repeat padding-box`,
                height: 'calc(270px - 5rem)',
                position: 'fixed',
                top: 0,
                zIndex: 1
              }}
            />
          </motion.div>
        )}
        {children}
        <Footer
          hide={footerOptions?.hide}
          darkMode={footerOptions?.darkMode}
          sx={footerOptions?.sx}
        />
      </BackgroundVideo>
    </Container>
  )
}

export default PageBase
