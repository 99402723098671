import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import TranslationEN from '../locales/en.json'

const resources = {
  en: {
    translation: TranslationEN
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator'],
      // keys or params to lookup language from
      lookupQuerystring: 'lang',
      lookupCookie: 'lang',
      lookupLocalStorage: 'lang',
      // cache user language on
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
      // optional expire and domain for set cookie
      cookieMinutes: 525600,
      // cookieDomain: GenericUtil.getDomain(),
      // only detect languages that are in the whitelist
      // checkWhitelist: true,
      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: { path: '/' }
    },
    react: {
      useSuspense: true
    },
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    fallbackNS: [],
    debug: false
  })

export default i18n
