import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import React, { useMemo, useState } from 'react'

import wavesBgVideoFallback from '../../assets/waves-background-video.png'
import {
  DefaultPageBaseAnimations,
  DefaultPageBaseAnimationsKeys,
  PageBase,
  SGHeading
} from '../../components'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import { BuildWithUsButton, BuildWithUsVentureSection } from './components'

const imageKitWavesUrl =
  'https://ik.imagekit.io/1e9yyhfbfj/videos/waves-background-video_4QKaMNCAJ.mp4/ik-master.m3u8?tr=sr-480_720_1080_1440'

const HeadingData = [{ topText: 'The society has a', bottomText: 'problem to solve?' }]

enum ZoomInOverlayVariants {
  InitialBubble = 'InitialBubble',
  ScaledDownBubble = 'ScaledDownBubble',
  FillPageBubble = 'FillPageBubble'
}

const BuildWithUsPage: React.FC<{}> = () => {
  const theme = useTheme()
  const isMobile = useBreakpoint(1180)
  const windowWidth = window.innerWidth
  const windowHeight = window.innerHeight
  // MARK: Could not start with like a 1px because on ios/safari the div would be totally pixelated
  const initialSize = 100
  const finalScaleFactor = Math.max(windowWidth / initialSize, windowHeight / initialSize) * 1.3

  const overlayFinalOpacity = 0.75
  const [showFullPageOverlay, setFullPageOverlay] = useState(false)
  const [showVenture, setShowVenture] = useState(false)

  const transitionDuration = 0.7

  const zoomInOverlayVariants: Variants = useMemo(
    () => ({
      [ZoomInOverlayVariants.InitialBubble]: {
        top: '50%',
        left: '50%',
        translateY: '-50%',
        translateX: '-50%',
        width: initialSize,
        minHeight: initialSize,
        borderRadius: '100%'
      },
      [ZoomInOverlayVariants.ScaledDownBubble]: {
        opacity: 1,
        scale: 0.01
      },
      [ZoomInOverlayVariants.FillPageBubble]: {
        opacity: overlayFinalOpacity,
        scale: finalScaleFactor
      }
    }),
    [finalScaleFactor]
  )

  const PageContent = useMemo(
    () => (
      // MARK: by changing the key, the component will be re-rendered. This fixes transitions bugs if a screen passes from desktop to mobile or vice-versa.
      <AnimatePresence key={`content-${isMobile ? 'mobile' : 'desktop'}`} exitBeforeEnter={false}>
        {!showVenture && (
          <motion.div
            key='build-with-us-cta-container'
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            transition={{ duration: transitionDuration }}
            exit={{
              ...(isMobile ? { x: '-100%' } : { y: '-75%' }),
              opacity: 0
            }}
          >
            <SGHeading variant='white' size='big' align='center' data={HeadingData} />
            <BuildWithUsButton
              variant='contained'
              color='secondary'
              onClick={() => setShowVenture(true)}
            >
              <Typography variant='h6' fontWeight='600'>
                Build your own venture
              </Typography>
            </BuildWithUsButton>
          </motion.div>
        )}
        {showVenture && (
          <motion.div
            key='venture-container'
            style={{
              ...(isMobile
                ? {}
                : {
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '100%',
                    width: '100%'
                  }),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            transition={{ duration: transitionDuration }}
            initial={{ opacity: 0.5, ...(isMobile ? { x: '100%' } : { y: '75%' }) }}
            animate={{ opacity: 1, [isMobile ? 'x' : 'y']: '0%' }}
          >
            <BuildWithUsVentureSection isMobile={isMobile} />
          </motion.div>
        )}
      </AnimatePresence>
    ),
    [isMobile, showVenture]
  )

  const isScreenRetardedSize = useMediaQuery(
    '@media screen and ( min-width: 1200px ) and ( max-height: 855px )'
  )

  return (
    <PageBase
      disableDefaultAnimations
      videoOptions={{
        src: imageKitWavesUrl,
        useHls: true,
        overlay: {
          overlayColor: theme.palette.primary.main,
          opacity: overlayFinalOpacity,
          motionOptions: {
            style: { zIndex: showFullPageOverlay ? -3 : -1 },
            variants: zoomInOverlayVariants,
            transition: {
              duration: transitionDuration
            },
            initial: [ZoomInOverlayVariants.InitialBubble, ZoomInOverlayVariants.ScaledDownBubble],
            animate: ZoomInOverlayVariants.FillPageBubble,
            exit: ZoomInOverlayVariants.ScaledDownBubble,
            onAnimationStart: () => setFullPageOverlay(false),
            onAnimationComplete: (definition) => {
              if (definition !== ZoomInOverlayVariants.ScaledDownBubble) setFullPageOverlay(true)
            }
          }
        },
        posterSrc: wavesBgVideoFallback,
        fallbackSrc: wavesBgVideoFallback
      }}
      footerOptions={{ hide: isMobile }}
      fullHeight={!isScreenRetardedSize}
    >
      <div
        id='animation-end-overlay'
        style={{
          opacity: showFullPageOverlay ? overlayFinalOpacity : 0,
          backgroundColor: theme.palette.primary.main,
          position: 'fixed',
          width: '100%',
          minHeight: '100vh',
          zIndex: -1
        }}
      />
      <motion.div
        variants={DefaultPageBaseAnimations}
        transition={{
          duration: transitionDuration / 2
        }}
        initial={DefaultPageBaseAnimationsKeys.Faded}
        animate={DefaultPageBaseAnimationsKeys.Visible}
        exit={DefaultPageBaseAnimationsKeys.Hidden}
        style={{
          height: '100vh',
          width: '100%'
        }}
      >
        {PageContent}
      </motion.div>
    </PageBase>
  )
}

export default BuildWithUsPage
