import { Button, Grid, styled } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import buildingIcon from '../../assets/icons/Building.svg'
import pathIcon from '../../assets/icons/Path.svg'
import seedIcon from '../../assets/icons/Seed.svg'
import wavesBgVideoFallback from '../../assets/waves-background-video.png'
import { PageBase, Section, VerticalCarousel } from '../../components'
import { SectionProps } from '../../components/Section'
import { VerticalCarouselProps } from '../../components/VerticalCarousel'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import { ManifestoStatement } from '../Manifesto/components'

export enum FAQPageType {
  founders = 'founders',
  launchers = 'launchers'
}

type VerticalCarouselBasePageProps = {
  captionText: string
  headingText: string
  titleText: string
  bodyText: string
  cardsData: string[]
  faqPageType: FAQPageType
  sectionProps?: Partial<SectionProps>
} & Pick<VerticalCarouselProps, 'FooterComponent'>

const imageKitWavesUrl =
  'https://ik.imagekit.io/1e9yyhfbfj/videos/waves-background-video_4QKaMNCAJ.mp4/ik-master.m3u8?tr=sr-480_720_1080_1440'

export const VerticalCarouselBasePage: React.FC<VerticalCarouselBasePageProps> = ({
  captionText,
  headingText,
  titleText,
  bodyText,
  cardsData,
  faqPageType,
  sectionProps,
  FooterComponent
}) => {
  const [showCta, setShowCta] = useState(false)
  const navigate = useNavigate()
  const isMobile = useBreakpoint(1180)
  const VerticalCarouselItemHeight = 346

  return (
    <PageBase
      videoOptions={{
        contentStyle: isMobile ? {} : { overflow: 'hidden' },
        src: imageKitWavesUrl,
        useHls: true,
        posterSrc: wavesBgVideoFallback,
        fallbackSrc: wavesBgVideoFallback,
        overlay: {
          overlayColor: '#000',
          opacity: 0
        }
      }}
      navbarOptions={{
        darkMode: true
      }}
      footerOptions={{
        hide: true
      }}
      fullHeight={!isMobile}
    >
      <Section
        caption={captionText}
        heading={headingText}
        title={titleText}
        body={bodyText}
        cta={
          (showCta || isMobile) && [
            {
              text: 'Apply now',
              onClick: () => navigate('/careers')
            },
            {
              text: 'FAQ',
              onClick: () => navigate(`/faq/${faqPageType}`)
            }
          ]
        }
        childrenContainerStyle={
          isMobile ? {} : { height: VerticalCarouselItemHeight, width: '100%' }
        }
        {...sectionProps}
        sx={{
          ...(isMobile
            ? {}
            : {
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
              }),
          marginTop: 0,
          ...sectionProps?.sx
        }}
        fullHeight={false}
      >
        <VerticalCarousel
          itemWidth={504}
          itemHeight={VerticalCarouselItemHeight}
          isMobile={isMobile}
          data={cardsData}
          FooterComponent={FooterComponent}
          onScroll={(index, didEnd) => setShowCta(didEnd)}
          shouldGoBack={() => navigate(-1)}
        />
      </Section>
    </PageBase>
  )
}

export const BuildWithUsButton = styled(Button)(({ theme }) => ({
  background: '#FFFFFF4D 0% 0% no-repeat padding-box',
  '&:hover': {
    background: '#FFFFFF4D 0% 0% no-repeat padding-box'
  },
  marginTop: '1.875rem'
}))

export const BuildWithUsVentureSection: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const { t } = useTranslation()
  const CtaButtons: string[] = t('buildWithUs.venture.cta', { returnObjects: true })
  const StatementsData: { title: string; body: string }[] = t('buildWithUs.venture.statements', {
    returnObjects: true
  })

  const Addition = useMemo(
    () => (
      <Grid container flexDirection={isMobile ? 'column' : 'row'} justifyContent='space-between'>
        {CtaButtons.map((text, index) => (
          <BuildWithUsButton
            key={`cta-${index}`}
            href={index === 0 ? '/build-with-us/launchers' : '/build-with-us/founders'}
            variant='contained'
            color='secondary'
            sx={{
              flexBasis: '48%',
              whiteSpace: 'nowrap'
            }}
          >
            {text}
          </BuildWithUsButton>
        ))}
      </Grid>
    ),
    [CtaButtons, isMobile]
  )

  return (
    <Section
      heading='Let’s shape'
      title='the future together'
      body='We provide financial and operative support to those who wants to build something better, for as many people as possible. If you are not ready to become a founder yet, we are also looking for Launchers.'
      addition={Addition}
      innerBoxProps={{ sx: { width: '100%' } }}
      darkMode
      needsFooter={isMobile}
      footerProps={{ darkMode: false }}
    >
      {StatementsData.map(({ title, body }, index) => (
        <ManifestoStatement
          key={`statement-${index}`}
          icon={index === 0 ? buildingIcon : index === 1 ? seedIcon : pathIcon}
          title={title}
          body={body}
          darkMode
        />
      ))}
    </Section>
  )
}
