import { Box } from '@mui/material'
import { motion } from 'framer-motion'
import React from 'react'

import designIcon from '../../assets/icons/Design.svg'
import itIcon from '../../assets/icons/Dev.svg'
import engineeringIcon from '../../assets/icons/Engineering.svg'
import financeIcon from '../../assets/icons/Finance.svg'
import hrIcon from '../../assets/icons/HR.svg'
import legalIcon from '../../assets/icons/Legal.svg'
import operationIcon from '../../assets/icons/Operation.svg'
import recruitingIcon from '../../assets/icons/Recruiting.svg'
import wavesBgVideoFallback from '../../assets/waves-background-video.png'
import { NavigationArrows, PageBase, Section } from '../../components'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import { useIsMobile } from '../../hooks/useIsMobile'
import { ManifestoStatement, TeamDepartment } from './components'

const imageKitWavesUrl =
  'https://ik.imagekit.io/1e9yyhfbfj/videos/waves-background-video_4QKaMNCAJ.mp4/ik-master.m3u8?tr=sr-480_720_1080_1440'

const ManifestoMeetusPage: React.FC<{}> = () => {
  const isMobile = useIsMobile()
  const isKindaLargeButNotEnough = useBreakpoint(1300)

  return (
    <PageBase
      key={`manifesto-meetus-${isMobile ? 'mobile' : 'desktop'}`}
      videoOptions={{
        src: imageKitWavesUrl,
        useHls: true,
        posterSrc: wavesBgVideoFallback,
        fallbackSrc: wavesBgVideoFallback,
        overlay: { overlayColor: 'transparent' }
      }}
      navbarOptions={{ darkMode: true }}
      footerOptions={{ darkMode: true }}
      fullHeight={!isMobile}
    >
      <Section
        needsAnimation
        animationOptions={
          isMobile
            ? {
                initial: { x: '100%', opacity: 0.1 },
                animate: { x: '0%', opacity: 1 },
                exit: { x: '100%', opacity: 0.1 }
              }
            : {}
        }
        needsFooter={false}
        heading='Your built-in'
        title='Founding team'
        body="We’re an experienced team of operators who have started dozens of companies by shaping people's lives. We are on our journey to create a better society by creating impactful companies."
        cta={{ text: 'Meet our team', href: '/team' }}
        leftSideShrinkable
        addition={
          <NavigationArrows
            routes={['/manifesto', '/manifesto/meetus']}
            sx={{ marginTop: '2rem' }}
          />
        }
      >
        <motion.div
          {...(isMobile
            ? {
                transition: { ease: 'easeInOut', bounce: 0 },
                initial: { x: '100%', opacity: 0.1 },
                animate: { x: '0%', opacity: 1 },
                exit: { x: '100%', opacity: 0.1 }
              }
            : {
                transition: { ease: 'easeInOut', bounce: 0 },
                initial: { x: '100%', opacity: 0 },
                animate: { x: '0%', opacity: 1 },
                exit: { x: '100%', opacity: 0 }
              })}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplate: isMobile
                ? 'repeat(4, 1fr) / repeat(2, 1fr)'
                : 'repeat(2, 1fr) / repeat(4, 1fr)',
              gap: isKindaLargeButNotEnough ? '1.75rem' : '3.75rem'
            }}
          >
            <TeamDepartment icon={designIcon} name='Design' />
            <TeamDepartment icon={engineeringIcon} name='Engineering' />
            <TeamDepartment icon={financeIcon} name='Finance' />
            <TeamDepartment icon={hrIcon} name='HR' />
            <TeamDepartment icon={itIcon} name='IT' />
            <TeamDepartment icon={legalIcon} name='Legal' />
            <TeamDepartment icon={operationIcon} name='Operation' />
            <TeamDepartment icon={recruitingIcon} name='Marketing' />
          </Box>
        </motion.div>
      </Section>
    </PageBase>
  )
}

export default ManifestoMeetusPage
