import React from 'react'
import { useTranslation } from 'react-i18next'

import { FAQPageType, VerticalCarouselBasePage } from './components'

const BuildWithUsFoundersPage: React.FC<{}> = () => {
  const { t } = useTranslation()
  const CardsTexts: string[] = t('buildWithUs.founders.cards', { returnObjects: true })

  return (
    <VerticalCarouselBasePage
      captionText={t('buildWithUs.founders.caption')}
      headingText={t('buildWithUs.founders.heading')}
      titleText={t('buildWithUs.founders.title')}
      bodyText={t('buildWithUs.founders.body')}
      cardsData={CardsTexts}
      sectionProps={{ leftSideShrinkable: true }}
      faqPageType={FAQPageType.founders}
    />
  )
}

export default BuildWithUsFoundersPage
