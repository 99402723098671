import React, { FC } from 'react'

import { NavLink } from './components'

type NavLinksProps = {
  isMobile?: boolean
  color?: string
  display: boolean
}

type Link = {
  href: string
  title: string
  external?: boolean
}

const NavLinks: FC<NavLinksProps> = ({ isMobile, color, display }) => {
  const links: Link[] = [
    { href: '/manifesto', title: 'Manifesto' },
    { href: '/team', title: 'Team' },
    { href: '/careers', title: 'Careers' },
    { href: '/companies', title: 'Portfolio' },
    { href: '::https://medium.com/startupgym', title: 'Blog', external: true }
  ]

  return (
    <>
      {links.map((link: Link, i: number) => (
        <NavLink
          color={color}
          display={display.toString()}
          href={`${link.href}`}
          sx={isMobile ? styles.mobileNavLink : { marginRight: '2rem' }}
          key={i}
          external={link.external}
        >
          {link.title}
        </NavLink>
      ))}
    </>
  )
}

export default NavLinks

const styles = {
  mobileNavLink: {
    fontSize: '2.25rem',
    fontWeight: 600,
    marginBottom: '1.5rem'
  }
}
